import React from 'react';
import './FunctionsSidebar.css';
import { getAvailableFunctions } from '../utils/spreadsheetFunctions';

export function FunctionsSidebar({ onStartTour }) {
    const handleStartTour = () => {
        if (onStartTour) {
            onStartTour();
        }
    };

    return (
        <div className="sidebar-right">
            <div className="sidebar-header">
                <h2>Funciones disponibles</h2>
                <button className="tour-button" onClick={handleStartTour}>
                    <span className="tour-icon">🎯</span>
                    Empezar tour
                </button>
            </div>
            <div className="functions-list">
                {getAvailableFunctions().map((func) => (
                    <div key={func.title} className="function-item">
                        <h3>
                            <span className='function-icon'>⚡</span>
                            <span className='function-title'>{func.title}</span>
                        </h3>
                        <p><strong>Descripción:</strong> {func.description}</p>
                        <p><strong>Fórmula:</strong> <code>{func.formula}</code></p>
                        <p><strong>Ejemplo:</strong> <code>{func.ejemplo}</code></p>
                    </div>
                ))}
            </div>
        </div>
    );
}