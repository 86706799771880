import * as React from 'react';
import { Container, Heading, Text, Button, Flex, Box, Grid } from '@radix-ui/themes';
import { CursorArrowIcon } from '@radix-ui/react-icons';
import TrustedCompanies from './TrustedCompanies';
import backgroundImage from '../../assets/images/hero_image.png';
import '../../styles/Hero.css'
import { Link as ScrollLink } from 'react-scroll';
import { Link } from 'react-router-dom';

const Hero = () => (
  <Box>
    <Box
      style={{
        backgroundColor: '#0A2540', // Color de fondo oscuro
        minHeight: '600px', // Altura ajustada
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <Container size="5" py="6">
        <Grid columns="2" gap="6" align="center">
          <Flex direction="column" align="flex-start">
            <Text style={{ color: '#4CAF50', marginBottom: '1rem', fontSize: '1.777rem' }}>
              Conecta Mercado Libre con Google Sheets
            </Text>
            <Heading className='landing-hero-title'>
              Transforma tus datos en decisiones inteligentes
            </Heading>
            <Text className='landing-hero-description'>
              Sincroniza automáticamente tus métricas de Mercado Libre con Google Sheets en tiempo real 24/7
            </Text>
            <Flex gap="6">
              <ScrollLink to='HowItWorks' smooth={true}>
                <Button size="4" className="custom-button-hero">
                  Cómo Funciona
                  <CursorArrowIcon style={{ marginLeft: '8px' }} />
                </Button>
              </ScrollLink>
              <Link style={{ textDecoration: 'none' }} to={'/demo'}>
                <Button size="4" variant="outline" className="outline-button-hero">
                  Probar Demo
                </Button>
              </Link>
            </Flex>
          </Flex>
          <Box>
            {/* Aquí puedes colocar la imagen de Google Sheets y Seller Data */}
            <div style={{ width: '100%', height: '300px', backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${backgroundImage})` }}>
              {/* Placeholder para tu imagen */}
            </div>
          </Box>
        </Grid>
      </Container>
    </Box>
    <Box py="4" style={{ backgroundColor: 'white' }}>
      <Container>
        <TrustedCompanies />
      </Container>
    </Box>
  </Box>
);

export default Hero;