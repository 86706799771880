export const data = {
    "ELECSHOP": {
        "publicaciones": [
            {
                "id": "MLM2083728615",
                "sku": "ELE-10-321",
                "title": "Playstation 5 Slim Standard 1tb Bundle Con 2 Juegos Físicos",
                "price": 10999,
                "promo": 7896,
                "stock": 9,
                "type_publi": "gold_pro",
                "logistic_type": "fullfilment",
                "url": "https://www.mercadolibre.com.mx/playstation-5-slim-standard-1tb-bundle-con-2-juegos-fisicos/p/MLM36385726?pdp_filters=deal:MLM1114851-1#wid=MLM2059035641&sid=search&searchVariation=MLM36385726&position=7&search_layout=grid&type=product&tracking_id=7228aaff-553e-4e2d-95df-a90c825e149c&DEAL_ID=MLM1079620-1&S=landingHubconsolas-y-videojuegos&V=14&T=CarouselDynamic-home&L=PRECIOS-ESPECIALES&deal_print_id=28bedfa0-b4b6-11ef-88cc-29c2031dd734&c_id=carouseldynamic-home&c_element_order=undefined&c_campaign=PRECIOS-ESPECIALES&c_uid=28bedfa0-b4b6-11ef-88cc-29c2031dd734",
                "status": "active",
                "inventory_id": "MLM42533"
            },
            {
                "id": "MLM918730800",
                "sku": "ELE-24-963",
                "title": "VideoJuego Mario Kart 8 Deluxe Nintendo Switch Físico",
                "price": 915,
                "promo": 910,
                "stock": 3,
                "type_publi": "gold_special",
                "logistic_type": "fullfilment",
                "url": "https://www.mercadolibre.com.mx/videojuego-mario-kart-8-deluxe-nintendo-switch-fisico/p/MLM6407334?pdp_filters=deal:MLM1114851-1#wid=MLM918730800&sid=search&searchVariation=MLM6407334&position=4&search_layout=grid&type=product&tracking_id=7228aaff-553e-4e2d-95df-a90c825e149c&DEAL_ID=MLM1079620-1&S=landingHubconsolas-y-videojuegos&V=14&T=CarouselDynamic-home&L=PRECIOS-ESPECIALES&deal_print_id=28bedfa0-b4b6-11ef-88cc-29c2031dd734&c_id=carouseldynamic-home&c_element_order=undefined&c_campaign=PRECIOS-ESPECIALES&c_uid=28bedfa0-b4b6-11ef-88cc-29c2031dd734",
                "status": "active",
                "inventory_id": "MLM23234"
            },
            {
                "id": "MLM3174580262",
                "sku": "VID-63-134",
                "title": "Tarjeta de memoria SanDisk SDSQXAO-128G-GNCZN Nintendo Switch 128GB",
                "price": 299,
                "promo": 299,
                "stock": 0,
                "type_publi": "gold_special",
                "logistic_type": "drop_off",
                "url": "https://www.mercadolibre.com.mx/tarjeta-de-memoria-sandisk-sdsqxao-128g-gnczn-nintendo-switch-128gb/p/MLM9635238#reco_item_pos=2&reco_backend=ranker_compl&reco_backend_type=low_level&reco_client=pdp-p2p&reco_id=d3a9dfde-74be-49c5-aa9e-7b0d21beadfb&reco_model=ranker_entity_v2",
                "status": "paused",
                "inventory_id": null
            },
            {
                "id": "MLM3167098278",
                "sku": "VID-63-125",
                "title": "Batería Recargable Compatible Con Control Dualshock Ps4 Bx30 Color Negro",
                "price": 50,
                "promo": 45,
                "stock": 5,
                "type_publi": "gold_special",
                "logistic_type": "cross_docking",
                "url": "https://www.mercadolibre.com.mx/bateria-recargable-compatible-con-control-dualshock-ps4-bx30-color-negro/p/MLM37874982#reco_item_pos=0&reco_backend=ranker_compl&reco_backend_type=low_level&reco_client=pdp-p2p&reco_id=0b5acd2c-39d7-45f3-b34f-520d1e9f2fcb&reco_model=ranker_entity_v2",
                "status": "active",
                "inventory_id": null
            },
            {
                "id": "MLM2174397463",
                "sku": "COM-25-204",
                "title": "Disipador Ventilador Cooler Master Hyper 212 Led Turbo Argb 2rr-212tk-18pa-r1",
                "price": 999,
                "promo": 949,
                "stock": 13,
                "type_publi": "gold_pro",
                "logistic_type": "fullfilment",
                "url": "https://www.mercadolibre.com.mx/disipador-ventilador-cooler-master-hyper-212-led-turbo-argb-2rr-212tk-18pa-r1/p/MLM35100301?pdp_filters=item_id:MLM2174397463#wid=MLM2174397463&sid=search&is_advertising=true&searchVariation=MLM35100301&position=3&search_layout=stack&type=pad&tracking_id=748fdcf0-8ff3-4146-8b40-cc68fddc848b&is_advertising=true&ad_domain=VQCATCORE_LST&ad_position=3&ad_click_id=MDU0MmEyNWItODMyNS00MDRkLTkyNGYtZmZjZTViMzk3YmM2",
                "status": "active",
                "inventory_id": null
            }
        ],
        "devoluciones": [
            {
                "id": "MLM2174397463",
                "sku": "COM-25-204",
                "units": 3,
                "title": "Disipador Ventilador Cooler Master Hyper 212 Led Turbo Argb 2rr-212tk-18pa-r1"
            },
            {
                "id": "MLM2174397463",
                "sku": "COM-25-204",
                "units": 8,
                "title": "Disipador Ventilador Cooler Master Hyper 212 Led Turbo Argb 2rr-212tk-18pa-r1"
            },
            {
                "id": "MLM3167098278",
                "sku": "VID-63-125",
                "units": 1,
                "title": "Batería Recargable Compatible Con Control Dualshock Ps4 Bx30 Color Negro"
            },
            {
                "id": "MLM2083728615",
                "sku": "ELE-10-321",
                "units": 6,
                "title": "Playstation 5 Slim Standard 1tb Bundle Con 2 Juegos Físicos"
            }
        ],
        "retiros": [
            {
                "id": 32343,
                "bulto_id": 3234300,
                "inventory_id": "MLM42533",
                "item_id": "MLM2083728615",
                "sku": "ELE-10-321",
                "title": "Playstation 5 Slim Standard 1tb Bundle Con 2 Juegos Físicos",
                "units": 3,
                "date_created": "2024-10-21",
                "date_received": "2024-10-28"
            },
            {
                "id": 32343,
                "bulto_id": 3234300,
                "inventory_id": "MLM42533",
                "item_id": "MLM918730800",
                "sku": "ELE-24-963",
                "title": "VideoJuego Mario Kart 8 Deluxe Nintendo Switch Físico",
                "units": 1,
                "date_created": "2024-10-21",
                "date_received": "2024-10-28"
            },
            {
                "id": 32421,
                "bulto_id": 3234301,
                "inventory_id": "MLM42533",
                "item_id": "MLM2174397463",
                "sku": "COM-25-204",
                "title": "Disipador Ventilador Cooler Master Hyper 212 Led Turbo Argb 2rr-212tk-18pa-r1",
                "units": 10,
                "date_created": "2024-11-10",
                "date_received": "2024-11-21"
            }
        ],
        "ordenes": [
            {
                "date_created": "2024-06-06",
                "id": "2000009609008872",
                "title": "Playstation 5 Slim Standard 1tb Bundle Con 2 Juegos Físicos",
                "sku": "ELE-10-321",
                "post_id": "MLM2083728615",
                "units": 5,
                "price": 10999,
                "cart_id": 803942,
                "commission_percentage": null,
                "unit_price": null,
                "shipping_price": null,
                "status": "canceled"
            },
            {
                "date_created": "2024-06-22",
                "id": "2000003263399224",
                "title": "VideoJuego Mario Kart 8 Deluxe Nintendo Switch Físico",
                "sku": "ELE-24-963",
                "post_id": "MLM918730800",
                "units": 10,
                "price": 915,
                "cart_id": 564868,
                "commission_percentage": null,
                "unit_price": null,
                "shipping_price": null,
                "status": "paid"
            },
            {
                "date_created": "2024-05-04",
                "id": "2000008692128888",
                "title": "Tarjeta de memoria SanDisk SDSQXAO-128G-GNCZN Nintendo Switch 128GB",
                "sku": "VID-63-134",
                "post_id": "MLM3174580262",
                "units": 13,
                "price": 299,
                "cart_id": 426774,
                "commission_percentage": null,
                "unit_price": null,
                "shipping_price": null,
                "status": "canceled"
            },
            {
                "date_created": "2024-04-18",
                "id": "2000004436290985",
                "title": "Batería Recargable Compatible Con Control Dualshock Ps4 Bx30 Color Negro",
                "sku": "VID-63-125",
                "post_id": "MLM3167098278",
                "units": 13,
                "price": 50,
                "cart_id": 391373,
                "commission_percentage": null,
                "unit_price": null,
                "shipping_price": null,
                "status": "canceled"
            },
            {
                "date_created": "2024-08-25",
                "id": "2000001816726824",
                "title": "Disipador Ventilador Cooler Master Hyper 212 Led Turbo Argb 2rr-212tk-18pa-r1",
                "sku": "COM-25-204",
                "post_id": "MLM2174397463",
                "units": 2,
                "price": 999,
                "cart_id": 896094,
                "commission_percentage": null,
                "unit_price": null,
                "shipping_price": null,
                "status": "paid"
            },
            {
                "date_created": "2024-10-20",
                "id": "2000004904290021",
                "title": "Playstation 5 Slim Standard 1tb Bundle Con 2 Juegos Físicos",
                "sku": "ELE-10-321",
                "post_id": "MLM2083728615",
                "units": 14,
                "price": 10999,
                "cart_id": 955421,
                "commission_percentage": null,
                "unit_price": null,
                "shipping_price": null,
                "status": "canceled"
            },
            {
                "date_created": "2024-01-22",
                "id": "2000000615393990",
                "title": "VideoJuego Mario Kart 8 Deluxe Nintendo Switch Físico",
                "sku": "ELE-24-963",
                "post_id": "MLM918730800",
                "units": 6,
                "price": 915,
                "cart_id": 929168,
                "commission_percentage": null,
                "unit_price": null,
                "shipping_price": null,
                "status": "paid"
            },
            {
                "date_created": "2024-07-23",
                "id": "2000008907081924",
                "title": "Tarjeta de memoria SanDisk SDSQXAO-128G-GNCZN Nintendo Switch 128GB",
                "sku": "VID-63-134",
                "post_id": "MLM3174580262",
                "units": 3,
                "price": 299,
                "cart_id": 440844,
                "commission_percentage": null,
                "unit_price": null,
                "shipping_price": null,
                "status": "canceled"
            },
            {
                "date_created": "2024-12-07",
                "id": "2000008947812564",
                "title": "Batería Recargable Compatible Con Control Dualshock Ps4 Bx30 Color Negro",
                "sku": "VID-63-125",
                "post_id": "MLM3167098278",
                "units": 5,
                "price": 50,
                "cart_id": 577161,
                "commission_percentage": null,
                "unit_price": null,
                "shipping_price": null,
                "status": "canceled"
            },
            {
                "date_created": "2024-09-03",
                "id": "2000004460248740",
                "title": "Disipador Ventilador Cooler Master Hyper 212 Led Turbo Argb 2rr-212tk-18pa-r1",
                "sku": "COM-25-204",
                "post_id": "MLM2174397463",
                "units": 1,
                "price": 999,
                "cart_id": 530979,
                "commission_percentage": null,
                "unit_price": null,
                "shipping_price": null,
                "status": "paid"
            },
            {
                "date_created": "2024-07-29",
                "id": "2000007553741128",
                "title": "Playstation 5 Slim Standard 1tb Bundle Con 2 Juegos Físicos",
                "sku": "ELE-10-321",
                "post_id": "MLM2083728615",
                "units": 4,
                "price": 10999,
                "cart_id": 209464,
                "commission_percentage": null,
                "unit_price": null,
                "shipping_price": null,
                "status": "paid"
            },
            {
                "date_created": "2024-07-24",
                "id": "2000005985844603",
                "title": "VideoJuego Mario Kart 8 Deluxe Nintendo Switch Físico",
                "sku": "ELE-24-963",
                "post_id": "MLM918730800",
                "units": 11,
                "price": 915,
                "cart_id": 972549,
                "commission_percentage": null,
                "unit_price": null,
                "shipping_price": null,
                "status": "paid"
            },
            {
                "date_created": "2024-03-11",
                "id": "2000007246980408",
                "title": "Tarjeta de memoria SanDisk SDSQXAO-128G-GNCZN Nintendo Switch 128GB",
                "sku": "VID-63-134",
                "post_id": "MLM3174580262",
                "units": 10,
                "price": 299,
                "cart_id": 382255,
                "commission_percentage": null,
                "unit_price": null,
                "shipping_price": null,
                "status": "paid"
            },
            {
                "date_created": "2024-06-08",
                "id": "2000007747290518",
                "title": "Batería Recargable Compatible Con Control Dualshock Ps4 Bx30 Color Negro",
                "sku": "VID-63-125",
                "post_id": "MLM3167098278",
                "units": 9,
                "price": 50,
                "cart_id": 853255,
                "commission_percentage": null,
                "unit_price": null,
                "shipping_price": null,
                "status": "paid"
            },
            {
                "date_created": "2024-08-28",
                "id": "2000005702545696",
                "title": "Disipador Ventilador Cooler Master Hyper 212 Led Turbo Argb 2rr-212tk-18pa-r1",
                "sku": "COM-25-204",
                "post_id": "MLM2174397463",
                "units": 2,
                "price": 999,
                "cart_id": 377639,
                "commission_percentage": null,
                "unit_price": null,
                "shipping_price": null,
                "status": "canceled"
            }
        ],
        "catalogo": [
            {
                "id": "MLM2083728615",
                "sku": "ELE-10-321",
                "title": "Playstation 5 Slim Standard 1tb Bundle Con 2 Juegos Físicos",
                "price": 10999,
                "promo": 7896,
                "stock": 9,
                "type_publi": "gold_pro",
                "logistic_type": "fullfilment",
                "url": "https://www.mercadolibre.com.mx/playstation-5-slim-standard-1tb-bundle-con-2-juegos-fisicos/p/MLM36385726?pdp_filters=deal:MLM1114851-1#wid=MLM2059035641&sid=search&searchVariation=MLM36385726&position=7&search_layout=grid&type=product&tracking_id=7228aaff-553e-4e2d-95df-a90c825e149c&DEAL_ID=MLM1079620-1&S=landingHubconsolas-y-videojuegos&V=14&T=CarouselDynamic-home&L=PRECIOS-ESPECIALES&deal_print_id=28bedfa0-b4b6-11ef-88cc-29c2031dd734&c_id=carouseldynamic-home&c_element_order=undefined&c_campaign=PRECIOS-ESPECIALES&c_uid=28bedfa0-b4b6-11ef-88cc-29c2031dd734",
                "status": "active",
                "inventory_id": "MLM42533",
                "id_catalog": "MLM8473368357",
                "url_catalog": "https://www.mercadolibre.com.mx/playstation-5-slim-standard-1tb-bundle-con-2-juegos-fisicos/p/MLM36385726?pdp_filters=deal:MLM1114851-1#wid=MLM2059035641&sid=search&searchVariation=MLM36385726&position=7&search_layout=grid&type=product&tracking_id=7228aaff-553e-4e2d-95df-a90c825e149c&DEAL_ID=MLM1079620-1&S=landingHubconsolas-y-videojuegos&V=14&T=CarouselDynamic-home&L=PRECIOS-ESPECIALES&deal_print_id=28bedfa0-b4b6-11ef-88cc-29c2031dd734&c_id=carouseldynamic-home&c_element_order=undefined&c_campaign=PRECIOS-ESPECIALES&c_uid=28bedfa0-b4b6-11ef-88cc-29c2031dd734",
                "envio": "Cliente",
                "ventas_7": 14,
                "ventas_15": 5,
                "ventas_30": 28,
                "ventas_60": 17,
                "ventas_90": 50,
                "ventas_365": 22,
                "status_winner": "Publicación no listada",
                "percent": '26.14%',
                "winner_price": 7896,
                "winner_user": "No compitiendo",
                "sharing_catalog": "No compitiendo",
                "price_to_win": 7896,
                "catalog": "No",
                "costo_envio": 126,
                "commission_percentage": 20,
                "cost_per_unit": 31,
                "paused_days": 318
            },
            {
                "id": "MLM918730800",
                "sku": "ELE-24-963",
                "title": "VideoJuego Mario Kart 8 Deluxe Nintendo Switch Físico",
                "price": 915,
                "promo": 910,
                "stock": 3,
                "type_publi": "gold_special",
                "logistic_type": "fullfilment",
                "url": "https://www.mercadolibre.com.mx/videojuego-mario-kart-8-deluxe-nintendo-switch-fisico/p/MLM6407334?pdp_filters=deal:MLM1114851-1#wid=MLM918730800&sid=search&searchVariation=MLM6407334&position=4&search_layout=grid&type=product&tracking_id=7228aaff-553e-4e2d-95df-a90c825e149c&DEAL_ID=MLM1079620-1&S=landingHubconsolas-y-videojuegos&V=14&T=CarouselDynamic-home&L=PRECIOS-ESPECIALES&deal_print_id=28bedfa0-b4b6-11ef-88cc-29c2031dd734&c_id=carouseldynamic-home&c_element_order=undefined&c_campaign=PRECIOS-ESPECIALES&c_uid=28bedfa0-b4b6-11ef-88cc-29c2031dd734",
                "status": "active",
                "inventory_id": "MLM23234",
                "id_catalog": "MLM2776171847",
                "url_catalog": "https://www.mercadolibre.com.mx/videojuego-mario-kart-8-deluxe-nintendo-switch-fisico/p/MLM6407334?pdp_filters=deal:MLM1114851-1#wid=MLM918730800&sid=search&searchVariation=MLM6407334&position=4&search_layout=grid&type=product&tracking_id=7228aaff-553e-4e2d-95df-a90c825e149c&DEAL_ID=MLM1079620-1&S=landingHubconsolas-y-videojuegos&V=14&T=CarouselDynamic-home&L=PRECIOS-ESPECIALES&deal_print_id=28bedfa0-b4b6-11ef-88cc-29c2031dd734&c_id=carouseldynamic-home&c_element_order=undefined&c_campaign=PRECIOS-ESPECIALES&c_uid=28bedfa0-b4b6-11ef-88cc-29c2031dd734",
                "envio": "Vendedor",
                "ventas_7": 9,
                "ventas_15": 47,
                "ventas_30": 12,
                "ventas_60": 9,
                "ventas_90": 33,
                "ventas_365": 46,
                "status_winner": "Perdiendo",
                "percent": 41.53,
                "winner_price": 910,
                "winner_user": "ELECSHOP",
                "sharing_catalog": "Compartiendo primer lugar",
                "price_to_win": 910,
                "catalog": "No",
                "costo_envio": 95,
                "commission_percentage": 14,
                "cost_per_unit": 34,
                "paused_days": 346
            },
            {
                "id": "MLM3174580262",
                "sku": "VID-63-134",
                "title": "Tarjeta de memoria SanDisk SDSQXAO-128G-GNCZN Nintendo Switch 128GB",
                "price": 299,
                "promo": 299,
                "stock": 0,
                "type_publi": "gold_special",
                "logistic_type": "drop_off",
                "url": "https://www.mercadolibre.com.mx/tarjeta-de-memoria-sandisk-sdsqxao-128g-gnczn-nintendo-switch-128gb/p/MLM9635238#reco_item_pos=2&reco_backend=ranker_compl&reco_backend_type=low_level&reco_client=pdp-p2p&reco_id=d3a9dfde-74be-49c5-aa9e-7b0d21beadfb&reco_model=ranker_entity_v2",
                "status": "paused",
                "inventory_id": null,
                "id_catalog": "MLM9438401745",
                "url_catalog": "https://www.mercadolibre.com.mx/tarjeta-de-memoria-sandisk-sdsqxao-128g-gnczn-nintendo-switch-128gb/p/MLM9635238#reco_item_pos=2&reco_backend=ranker_compl&reco_backend_type=low_level&reco_client=pdp-p2p&reco_id=d3a9dfde-74be-49c5-aa9e-7b0d21beadfb&reco_model=ranker_entity_v2",
                "envio": "Cliente",
                "ventas_7": 9,
                "ventas_15": 20,
                "ventas_30": 19,
                "ventas_60": 36,
                "ventas_90": 24,
                "ventas_365": 27,
                "status_winner": "Perdiendo",
                "percent": 28.81,
                "winner_price": 299,
                "winner_user": "No compitiendo",
                "sharing_catalog": "No compitiendo",
                "price_to_win": 299,
                "catalog": "Si",
                "costo_envio": 149,
                "commission_percentage": 27,
                "cost_per_unit": 23,
                "paused_days": 272
            },
            {
                "id": "MLM3167098278",
                "sku": "VID-63-125",
                "title": "Batería Recargable Compatible Con Control Dualshock Ps4 Bx30 Color Negro",
                "price": 50,
                "promo": 45,
                "stock": 5,
                "type_publi": "gold_special",
                "logistic_type": "cross_docking",
                "url": "https://www.mercadolibre.com.mx/bateria-recargable-compatible-con-control-dualshock-ps4-bx30-color-negro/p/MLM37874982#reco_item_pos=0&reco_backend=ranker_compl&reco_backend_type=low_level&reco_client=pdp-p2p&reco_id=0b5acd2c-39d7-45f3-b34f-520d1e9f2fcb&reco_model=ranker_entity_v2",
                "status": "active",
                "inventory_id": null,
                "id_catalog": "MLM5819260318",
                "url_catalog": "https://www.mercadolibre.com.mx/bateria-recargable-compatible-con-control-dualshock-ps4-bx30-color-negro/p/MLM37874982#reco_item_pos=0&reco_backend=ranker_compl&reco_backend_type=low_level&reco_client=pdp-p2p&reco_id=0b5acd2c-39d7-45f3-b34f-520d1e9f2fcb&reco_model=ranker_entity_v2",
                "envio": "Cliente",
                "ventas_7": 7,
                "ventas_15": 7,
                "ventas_30": 44,
                "ventas_60": 30,
                "ventas_90": 17,
                "ventas_365": 12,
                "status_winner": "Perdiendo",
                "percent": 42.13,
                "winner_price": 45,
                "winner_user": "No compitiendo",
                "sharing_catalog": "No compitiendo",
                "price_to_win": 45,
                "catalog": "Si",
                "costo_envio": 144,
                "commission_percentage": 37,
                "cost_per_unit": 25,
                "paused_days": 229
            },
            {
                "id": "MLM2174397463",
                "sku": "COM-25-204",
                "title": "Disipador Ventilador Cooler Master Hyper 212 Led Turbo Argb 2rr-212tk-18pa-r1",
                "price": 999,
                "promo": 949,
                "stock": 13,
                "type_publi": "gold_pro",
                "logistic_type": "fullfilment",
                "url": "https://www.mercadolibre.com.mx/disipador-ventilador-cooler-master-hyper-212-led-turbo-argb-2rr-212tk-18pa-r1/p/MLM35100301?pdp_filters=item_id:MLM2174397463#wid=MLM2174397463&sid=search&is_advertising=true&searchVariation=MLM35100301&position=3&search_layout=stack&type=pad&tracking_id=748fdcf0-8ff3-4146-8b40-cc68fddc848b&is_advertising=true&ad_domain=VQCATCORE_LST&ad_position=3&ad_click_id=MDU0MmEyNWItODMyNS00MDRkLTkyNGYtZmZjZTViMzk3YmM2",
                "status": "active",
                "inventory_id": null,
                "id_catalog": "MLM9816536604",
                "url_catalog": "https://www.mercadolibre.com.mx/disipador-ventilador-cooler-master-hyper-212-led-turbo-argb-2rr-212tk-18pa-r1/p/MLM35100301?pdp_filters=item_id:MLM2174397463#wid=MLM2174397463&sid=search&is_advertising=true&searchVariation=MLM35100301&position=3&search_layout=stack&type=pad&tracking_id=748fdcf0-8ff3-4146-8b40-cc68fddc848b&is_advertising=true&ad_domain=VQCATCORE_LST&ad_position=3&ad_click_id=MDU0MmEyNWItODMyNS00MDRkLTkyNGYtZmZjZTViMzk3YmM2",
                "envio": "Cliente",
                "ventas_7": 35,
                "ventas_15": 13,
                "ventas_30": 13,
                "ventas_60": 45,
                "ventas_90": 39,
                "ventas_365": 39,
                "status_winner": "Publicación no listada",
                "percent": 76.65,
                "winner_price": 949,
                "winner_user": "ELECSHOP",
                "sharing_catalog": "Compartiendo primer lugar",
                "price_to_win": 949,
                "catalog": "No",
                "costo_envio": 104,
                "commission_percentage": 11,
                "cost_per_unit": 34,
                "paused_days": 329
            }
        ]
    }
}