import React, { useRef, useEffect, useState } from 'react';
import { TourModals } from './TourModals';
import { initSpreadsheetFunctions } from '../utils/spreadsheetFunctions';
import jspreadsheet from 'jspreadsheet-ce';
import 'jspreadsheet-ce/dist/jspreadsheet.css';
import 'jsuites/dist/jsuites.css';
import $ from 'jquery';
import 'bootstrap';
import './styles.css';




window.jQuery = window.$ = $;
export let globalSpreadsheet = null;
export let currentFormulaCell = null;
window.formulaCells = {};


export function getCellCoordinates(cellRef) {
    const match = cellRef.match(/([A-Z]+)([0-9]+)/);
    const col = match[1].split('').reduce((acc, char) =>
        acc * 26 + (char.charCodeAt(0) - 'A'.charCodeAt(0)), 0);
    const row = parseInt(match[2]) - 1;
    console.log("Col y row", col, row);
    return [col, row];
}


export function SpreadsheetGrid({ toggleSidebar, onInitTour, isSidebarVisible }) {
    const spreadsheetRef = useRef(null);
    const jssRef = useRef(null);
    const [tourActive, setTourActive] = useState(false);
    const [showWelcomeModal, setShowWelcomeModal] = useState(true);
    const [showEndModal, setShowEndModal] = useState(false);
    const tourRef = useRef(null);
    const columnHeaders = ['SKU', 'ID Publicación', 'Titulo', 'Stock', 'Precio', 'URL', 'Status', 'Código ML'];



    useEffect(() => {
        // Aplica overflow: hidden de forma segura y forzada
        document.body.classList.add('overflow-hidden');

        // Limpia el estilo al desmontar el componente
        return () => {
            document.body.classList.remove('overflow-hidden');
        };
    }, []);



    // Función auxiliar para convertir referencia de celda a coordenadas
    function getCellCoordinates(cellRef) {
        const match = cellRef.match(/([A-Z]+)([0-9]+)/);
        const col = match[1].split('').reduce((acc, char) =>
            acc * 26 + (char.charCodeAt(0) - 'A'.charCodeAt(0)), 0);
        const row = parseInt(match[2]) - 1;
        console.log("Col y row", col, row);
        return [col, row];
    }



    // Función auxiliar para limpiar celdas relacionadas
    function clearRelatedCells(x, y, instance) {
        const formulaId = `${x},${y}`;
        if (window.formulaCells[formulaId]) {
            window.formulaCells[formulaId].forEach(coord => {
                try {
                    instance.jexcel.setValueFromCoords(coord.x, coord.y, '');
                } catch (e) {
                    console.error('Error al limpiar celda:', e);
                }
            });
            delete window.formulaCells[formulaId];
        }
    }



    const setFormulaInCell = (x, y, formula) => {
        if (spreadsheetRef.current && spreadsheetRef.current.jexcel) {
            const targetCell = document.querySelector(`.jexcel tbody td[data-x="${x}"][data-y="${y}"]`);
            if (targetCell) {
                // Limpiar selecciones previas
                const allCells = document.querySelectorAll('.jexcel tbody td');
                allCells.forEach(cell => cell.classList.remove('highlight'));
                targetCell.classList.add('highlight');

                // Establecer la selección
                spreadsheetRef.current.jexcel.updateSelections = function () {
                    this.selectedCell = targetCell;
                };

                //targetCell.click();
                setTimeout(() => {
                    spreadsheetRef.current.jexcel.setValueFromCoords(x, y, formula, true);
                }, 100);
            }
        }
    };


    const resetTour = () => {
        if (window.tour) {
            // Remover la clase highlight de cualquier celda que la tenga
            const highlightedCell = document.querySelector('.tour-highlighted-cell');
            if (highlightedCell) {
                highlightedCell.classList.remove('tour-highlighted-cell');
            }

            // Si estás usando spreadsheetRef, también puedes limpiar la selección
            if (spreadsheetRef.current && spreadsheetRef.current.jexcel) {
                spreadsheetRef.current.jexcel.resetSelection();
            }

            const cellsToReset = ['B1', 'B2', 'B3']
            if (spreadsheetRef.current && spreadsheetRef.current.jexcel) {
                cellsToReset.forEach(cell => {
                    spreadsheetRef.current.jexcel.setReadOnly(cell, false);
                });
            }
            window.tour.restart();
        } else {
            console.log('No hay tour');
        }
    }


    const typeText = (text, cell, baseSpeed = 100) => {
        return new Promise((resolve) => {
            let index = 0;
            spreadsheetRef.current.jexcel.setValue(cell, '');

            const getRandomDelay = () => {
                return baseSpeed + (Math.random() - 0.5) * baseSpeed;
            };

            const typeNextChar = () => {
                if (index < text.length) {
                    spreadsheetRef.current.jexcel.setValue(
                        cell,
                        text.substring(0, index + 1)
                    );
                    index++;
                    setTimeout(typeNextChar, getRandomDelay());
                } else {
                    // Bloquear la celda usando la referencia
                    spreadsheetRef.current.jexcel.setReadOnly(cell, true);
                    resolve();
                }
            };

            typeNextChar();
        });
    };




    const typeFormula = (formula, cell, baseSpeed = 100) => {
        return new Promise((resolve) => {
            let index = 0;

            // Extraer coordenadas x,y de la referencia de celda (ejemplo: 'B6' -> [1,5])
            const colLetter = cell.match(/[A-Z]+/)[0];
            const row = parseInt(cell.match(/\d+/)[0]) - 1;
            const col = colLetter.split('').reduce((acc, char) =>
                acc * 26 + char.charCodeAt(0) - 65, 0);

            const getRandomDelay = () => {
                return baseSpeed + (Math.random() - 0.5) * baseSpeed;
            };

            const typeNextChar = () => {
                if (index < formula.length) {
                    // Durante la escritura, mostrar como texto plano
                    const displayText = ' ' + formula.substring(0, index + 1);
                    spreadsheetRef.current.jexcel.setValueFromCoords(col, row, displayText, false);

                    index++;
                    setTimeout(typeNextChar, getRandomDelay());
                } else {
                    // Al finalizar, usar setFormulaInCell para establecer la fórmula correctamente
                    setTimeout(() => {
                        setFormulaInCell(col, row, formula);
                        resolve();
                    }, baseSpeed);
                }
            };

            // Limpiar la celda antes de empezar
            spreadsheetRef.current.jexcel.setValueFromCoords(col, row, '', false);
            typeNextChar();
        });
    };




    const setTextInCell = (text, cell) => {
        if (text.includes("=")) {
            const colLetter = cell.match(/[A-Z]+/)[0];
            const row = parseInt(cell.match(/\d+/)[0]) - 1;
            const col = colLetter.split('').reduce((acc, char) =>
                acc * 26 + char.charCodeAt(0) - 65, 0);
            setFormulaInCell(col, row, text);
        } else {
            spreadsheetRef.current.jexcel.setValue(cell, '');
            spreadsheetRef.current.jexcel.setValue(cell, text);
            spreadsheetRef.current.jexcel.setReadOnly(cell, true);
        }
    };


    const skipTour = async () => {
        try {
            if (spreadsheetRef.current && spreadsheetRef.current.jexcel) {
                // Limpiar la celda primero
                spreadsheetRef.current.jexcel.setValue('I6', '');
                spreadsheetRef.current.jexcel.setValue('H6', '');
                spreadsheetRef.current.jexcel.setValue('G6', '');
                spreadsheetRef.current.jexcel.setValue('F6', '');
                spreadsheetRef.current.jexcel.setValue('E6', '');
                spreadsheetRef.current.jexcel.setValue('D6', '');
                spreadsheetRef.current.jexcel.setValue('C6', '');
                spreadsheetRef.current.jexcel.setValue('B6', '');
                spreadsheetRef.current.jexcel.setValue('B1', '');
                // Esperar a que termine la animación
                await Promise.all([
                    setTextInCell('ELECSHOP', 'B1'),
                    setTextInCell('2022-01-01', 'B2'),
                    setTextInCell('2024-12-31', 'B3')
                ]);
                setTimeout(() => {
                    setTextInCell('=SHEETSELLER_SKUS(B1, "todos")', 'B6');
                }, 100);
                setTimeout(() => {
                    setTextInCell('=SHEETSELLER_ID(B1, B6:B10)', 'C6');
                }, 500);

                setTimeout(() => {
                    setTextInCell('=SHEETSELLER_TITULO(B1, C6:C10)', 'D6');
                }, 1000);
                setTimeout(() => {
                    setTextInCell('=SHEETSELLER_STOCK(B1,B6:B10,C6:C10)', 'E6');
                }, 1500);
                setTimeout(() => {
                    setTextInCell('=SHEETSELLER_PRECIO("ELECSHOP", B6:B10, C6:C10)', 'F6');
                }, 2000);
                setTimeout(() => {
                    setTextInCell('=SHEETSELLER_URL("ELECSHOP", B6:B10, C6:C10)', 'G6');
                }, 2500);
                setTimeout(() => {
                    setTextInCell('=SHEETSELLER_STATUS(B1, C6:C10)', 'H6');
                }, 3000);
                setTimeout(() => {
                    setTextInCell('=SHEETSELLER_CODIGOML("ELECSHOP", B6:B10, C6:C10)', 'I6');
                }, 3500);

                setTimeout(() => {
                }, 4000);

                // Limpiar el registro de celdas cuando se desmonta el componente
                setTimeout(() => {
                    const tourCell = document.querySelector('.tour-highlighted-cell');
                    if (tourCell) {
                        tourCell.classList.remove('tour-highlighted-cell');
                        tourCell.classList.remove('highlight'); // Remover también la selección
                    }

                    // Asegurarnos de que no queden celdas seleccionadas
                    const highlightedCells = document.querySelectorAll('.jexcel tbody td.highlight');
                    highlightedCells.forEach(cell => {
                        cell.classList.remove('highlight');
                    });

                    // Si estás usando jexcel directamente, también puedes usar:
                    if (spreadsheetRef.current && spreadsheetRef.current.jexcel) {
                        spreadsheetRef.current.jexcel.resetSelection();
                    }
                }
                    , 3800);
            }
            console.log("Saltando el tour");

        } catch (error) {
            console.error('Error al saltar el tour:', error);
        }
    };






    const initTour = () => {
        console.log('MENSAJE')
        try {
            if (tourRef.current) {
                delete window.tour;
                tourRef.current = null;
            }


            const tour = new window.Tour({
                name: 'spreadsheet-tour',
                framework: 'bootstrap4',
                steps: [
                    {
                        element: '.jexcel tbody td[data-x="2"][data-y="10"]',
                        title: "Bienvenido al Tour de SheetsellerApp",
                        content: "Este tour te guiará a través de las funciones básicas de la hoja de cálculo. Haz clic en 'Next' para comenzar.",
                        placement: 'bottom',
                        backdrop: true,  // Cambiado a true
                        backdropContainer: 'body', // Especificar el contenedor del backdrop
                        backdropPadding: 0,  // Ajustado para dar más espacio
                        reflex: false,
                        onShow: function (tour) {
                            // Asegurarse de que el backdrop se mantenga
                            document.body.style.overflow = 'hidden';
                        },
                        onHide: function (tour) {
                            // Limpiar cuando el paso se oculte
                            document.body.style.overflow = 'auto';
                        }
                    },
                    {
                        element: '.jexcel tbody td[data-x="1"][data-y="0"]',
                        title: "Nombre de la cuenta",
                        content: "Siempre es necesario el nombre de la cuenta para poder obtener información de la misma. En esta demo usaremos el nombre 'ELECSHOP'.",
                        placement: 'right',
                        backdrop: false,
                        backdropPadding: 0,
                        reflex: true,
                        onShow: function (tour) {
                            const element = document.querySelector('.jexcel tbody td[data-x="1"][data-y="0"]');
                            if (element) {
                                element.classList.add('tour-highlighted-cell');
                            }
                        },
                        onHide: function (tour) {
                            const element = document.querySelector('.tour-highlighted-cell');
                            if (element) {
                                element.classList.remove('tour-highlighted-cell');
                            }
                        },
                        onNext: async function () {
                            if (spreadsheetRef.current && spreadsheetRef.current.jexcel) {
                                // Limpiar la celda primero
                                spreadsheetRef.current.jexcel.setValue('B1', '');
                                // Esperar a que termine la animación
                                await typeText('ELECSHOP', 'B1', 150);
                            }
                        }
                    },
                    {
                        element: '.jexcel tbody td[data-x="1"][data-y="1"]',
                        title: "Fecha de inicio",
                        content: "La fecha de inicio es necesaria para obtener los datos de la cuenta. En esta demo usaremos la fecha '2022-01-01'.",
                        placement: 'right',
                        backdrop: false,
                        backdropPadding: 0,
                        reflex: true,
                        onShow: function (tour) {
                            const element = document.querySelector('.jexcel tbody td[data-x="1"][data-y="1"]');
                            if (element) {
                                element.classList.add('tour-highlighted-cell');
                            }
                        },
                        onHide: function (tour) {
                            const element = document.querySelector('.tour-highlighted-cell');
                            if (element) {
                                element.classList.remove('tour-highlighted-cell');
                            }
                        },
                        onNext: async function () {
                            if (spreadsheetRef.current && spreadsheetRef.current.jexcel) {
                                // Limpiar la celda primero
                                spreadsheetRef.current.jexcel.setValue('B2', '');
                                // Esperar a que termine la animación
                                await typeText('2022-01-01', 'B2', 150);
                            }
                        }
                    },
                    {
                        element: '.jexcel tbody td[data-x="1"][data-y="2"]',
                        title: "Fecha final",
                        content: "La fecha final es necesaria para obtener los datos de la cuenta. En esta demo usaremos la fecha '2022-12-31'.",
                        placement: 'right',
                        backdrop: false,
                        backdropPadding: 0,
                        reflex: true,
                        onShow: function (tour) {
                            const element = document.querySelector('.jexcel tbody td[data-x="1"][data-y="2"]');
                            if (element) {
                                element.classList.add('tour-highlighted-cell');
                            }
                        },
                        onHide: function (tour) {
                            const element = document.querySelector('.tour-highlighted-cell');
                            if (element) {
                                element.classList.remove('tour-highlighted-cell');
                            }
                        },
                        onNext: async function () {
                            if (spreadsheetRef.current && spreadsheetRef.current.jexcel) {
                                // Limpiar la celda primero
                                spreadsheetRef.current.jexcel.setValue('B3', '');
                                // Esperar a que termine la animación
                                await typeText('2024-12-31', 'B3', 150);
                            }
                        }
                    },
                    {
                        element: '.jexcel tbody td[data-x="1"][data-y="5"]',
                        title: "Obtener todos los SKUs",
                        content: "Para obtener todos los SKUs de la cuenta, es necesario usar la función: SHEETSELLER_SKUS(A1).",
                        placement: 'right',
                        backdrop: false,
                        backdropPadding: 0,
                        reflex: true,
                        onShow: function (tour) {
                            const element = document.querySelector('.jexcel tbody td[data-x="1"][data-y="5"]');
                            if (element) {
                                element.classList.add('tour-highlighted-cell');
                            }
                        },
                        onHide: function (tour) {
                            const element = document.querySelector('.tour-highlighted-cell');
                            if (element) {
                                element.classList.remove('tour-highlighted-cell');
                            }
                        },
                        onNext: async function () {
                            await typeFormula('=SHEETSELLER_SKUS(B1, "todos")', 'B6');
                        }
                    },
                    {
                        element: '.jexcel tbody td[data-x="2"][data-y="5"]',
                        title: "Obtener todos IDs de publicaciones",
                        content: "Obtengamos los IDs de las publicaciones. Esta formula necesita el Nickname y SKUs.",
                        placement: 'right',
                        backdrop: false,
                        backdropPadding: 0,
                        reflex: true,
                        onShow: function (tour) {
                            const element = document.querySelector('.jexcel tbody td[data-x="2"][data-y="5"]');
                            if (element) {
                                element.classList.add('tour-highlighted-cell');
                            }
                        },
                        onHide: function (tour) {
                            const element = document.querySelector('.tour-highlighted-cell');
                            if (element) {
                                element.classList.remove('tour-highlighted-cell');
                            }
                        },
                        onNext: async function () {
                            //setFormulaInCell(2, 5, '=SHEETSELLER_ID(B1, B6:B10)');
                            await typeFormula('=SHEETSELLER_ID(B1, B6:B10)', 'C6');
                        }
                    },
                    {
                        element: '.jexcel tbody td[data-x="3"][data-y="5"]',
                        title: "Obtener todos los titulos de publicaciones",
                        content: "Obtengamos los titulos de las publicaciones. Esta formula necesita el Nickname y el ID de cada publicación.",
                        placement: 'right',
                        backdrop: false,
                        backdropPadding: 0,
                        reflex: true,
                        onShow: function (tour) {
                            const element = document.querySelector('.jexcel tbody td[data-x="3"][data-y="5"]');
                            if (element) {
                                element.classList.add('tour-highlighted-cell');
                            }
                        },
                        onHide: function (tour) {
                            const element = document.querySelector('.tour-highlighted-cell');
                            if (element) {
                                element.classList.remove('tour-highlighted-cell');
                            }
                        },
                        onNext: async function () {
                            //setFormulaInCell(3, 5, '=SHEETSELLER_TITULO(B1, C6:C10)');
                            await typeFormula('=SHEETSELLER_TITULO(B1, C6:C10)', 'D6');
                        }
                    },
                    {
                        element: '.jexcel tbody td[data-x="4"][data-y="5"]',
                        title: "Obtener el stock de una publicación",
                        content: "Obtengamos el stock disponible de cada publicación. Es necesario el SKU y ID de publicación.",
                        placement: 'right',
                        backdrop: false,
                        backdropPadding: 0,
                        reflex: true,
                        onShow: function (tour) {
                            const element = document.querySelector('.jexcel tbody td[data-x="4"][data-y="5"]');
                            if (element) {
                                element.classList.add('tour-highlighted-cell');
                            }
                        },
                        onHide: function (tour) {
                            const element = document.querySelector('.tour-highlighted-cell');
                            if (element) {
                                element.classList.remove('tour-highlighted-cell');
                            }
                        },
                        onNext: async function () {
                            //setFormulaInCell(4, 5, '=SHEETSELLER_STOCK(B1,B6:B10,C6:C10)');
                            await typeFormula('=SHEETSELLER_STOCK(B1,B6:B10,C6:C10)', 'E6');
                        }
                    },
                    {
                        element: '.jexcel tbody td[data-x="5"][data-y="5"]',
                        title: "Obtener el precio de una publicación",
                        content: "Obtengamos el precio de cada publicación. En esta formula necesitamos el nombre de la cuenta y el par de SKU y ID.",
                        placement: 'right',
                        backdrop: false,
                        backdropPadding: 0,
                        reflex: true,
                        onShow: function (tour) {
                            const element = document.querySelector('.jexcel tbody td[data-x="5"][data-y="5"]');
                            if (element) {
                                element.classList.add('tour-highlighted-cell');
                            }
                        },
                        onHide: function (tour) {
                            const element = document.querySelector('.tour-highlighted-cell');
                            if (element) {
                                element.classList.remove('tour-highlighted-cell');
                            }
                        },
                        onNext: async function () {
                            //setFormulaInCell(5, 5, '=SHEETSELLER_PRECIO("ELECSHOP", B6:B10, C6:C10)');
                            const cell = document.querySelector('.jexcel tbody td[data-x="7"][data-y="5"]');
                            if (cell) {
                                cell.scrollIntoView({
                                    behavior: 'smooth',
                                    block: 'center',
                                    inline: 'center'
                                });
                                // Pequeña pausa para permitir que el scroll termine
                                await new Promise(resolve => setTimeout(resolve, 500));
                            }
                            await typeFormula('=SHEETSELLER_PRECIO("ELECSHOP", B6:B10, C6:C10)', 'F6');
                        }
                    },
                    {
                        element: '.jexcel tbody td[data-x="6"][data-y="5"]',
                        title: "Obtener la URL de una publicación",
                        content: "Obtengamos la URL de cada publicación. En esta formula necesitamos el nombre de la cuenta y el par de SKU y ID.",
                        placement: 'right',
                        backdrop: false,
                        backdropPadding: 0,
                        reflex: true,
                        onShow: function (tour) {
                            const element = document.querySelector('.jexcel tbody td[data-x="6"][data-y="5"]');
                            if (element) {
                                element.classList.add('tour-highlighted-cell');
                            }
                        },
                        onHide: function (tour) {
                            const element = document.querySelector('.tour-highlighted-cell');
                            if (element) {
                                element.classList.remove('tour-highlighted-cell');
                            }
                        },
                        onNext: async function () {
                            //setFormulaInCell(6, 5, '=SHEETSELLER_URL("ELECSHOP", B6:B10, C6:C10)');
                            await typeFormula('=SHEETSELLER_URL("ELECSHOP", B6:B10, C6:C10)', 'G6');
                        }
                    },
                    {
                        element: '.jexcel tbody td[data-x="7"][data-y="5"]',
                        title: "Obtener el estado de una publicación",
                        content: "Obtengamos el estado de cada publicación. En esta formula necesitamos el nombre de la cuenta y el ID de la publicación.",
                        placement: 'right',
                        backdrop: false,
                        backdropPadding: 0,
                        reflex: true,
                        onShow: function (tour) {
                            const element = document.querySelector('.jexcel tbody td[data-x="7"][data-y="5"]');
                            if (element) {
                                element.classList.add('tour-highlighted-cell');
                            }
                        },
                        onHide: function (tour) {
                            const element = document.querySelector('.tour-highlighted-cell');
                            if (element) {
                                element.classList.remove('tour-highlighted-cell');
                            }
                        },
                        onNext: async function () {
                            //setFormulaInCell(7, 5, '=SHEETSELLER_STATUS("ELECSHOP", C6:C10)');
                            await typeFormula('=SHEETSELLER_STATUS(B1, C6:C10)', 'H6');
                        }
                    },
                    {
                        element: '.jexcel tbody td[data-x="8"][data-y="5"]',
                        title: "Obtener el código ML de una publicación",
                        content: "Obtengamos el código ML de cada publicación. En esta formula necesitamos el nombre de la cuenta y el par de SKU y ID.",
                        placement: 'right',
                        backdrop: false,
                        backdropPadding: 0,
                        reflex: true,
                        onShow: function (tour) {
                            const element = document.querySelector('.jexcel tbody td[data-x="8"][data-y="5"]');
                            if (element) {
                                element.classList.add('tour-highlighted-cell');
                            }
                        },
                        onHide: function (tour) {
                            const element = document.querySelector('.tour-highlighted-cell');
                            if (element) {
                                element.classList.remove('tour-highlighted-cell');
                            }
                        },
                        onNext: async function () {
                            //setFormulaInCell(8, 5, '=SHEETSELLER_CODIGOML("ELECSHOP", B6:B10, C6:C10)');
                            await typeFormula('=SHEETSELLER_CODIGOML("ELECSHOP", B6:B10, C6:C10)', 'I6');
                        }
                    },
                    {
                        element: '.jexcel tbody td[data-x="7"][data-y="10"]',
                        title: "¡Felicidades! 🎉",
                        content: "Has completado el tour de SheetsellerApp. Si deseas volver a ver el tour, puedes hacerlo en cualquier momento. Disfruta de las otras formulas que tenemos para ti. 🚀",
                        placement: 'bottom',
                        backdrop: true,  // Cambiado a true
                        backdropContainer: 'body', // Especificar el contenedor del backdrop
                        backdropPadding: 5,  // Ajustado para dar más espacio
                        reflex: false,
                        onShow: function (tour) {
                            // Asegurarse de que el backdrop se mantenga
                            document.body.style.overflow = 'hidden';
                        },
                        onHide: function (tour) {
                            // Limpiar cuando el paso se oculte
                            document.body.style.overflow = 'auto';
                        }
                    },

                ],
                container: 'body',
                smartPlacement: true,
                backdrop: true,
                backdropPadding: 5,
                backdropContainer: 'body',
                onStart: function () {
                    setTourActive(true);
                },
                onShow: function (tour) {
                    const currentStep = tour._current;
                    const totalSteps = tour._options.steps.length;
                    const nextButton = document.querySelector('[data-role="next"]');
                    const prevButton = document.querySelector('[data-role="prev"]');
                    const endButton = document.querySelector('[data-role="end"]');

                    // Último paso
                    if (currentStep === totalSteps - 1) {
                        if (nextButton) nextButton.style.display = 'none';
                        if (endButton) endButton.style.display = 'inline-block';
                    } else {
                        if (nextButton) nextButton.style.display = 'inline-block';
                        if (endButton) endButton.style.display = 'none';
                    }

                    // Primer paso
                    if (currentStep === 0) {
                        if (prevButton) prevButton.style.display = 'none';
                    } else {
                        if (prevButton) prevButton.style.display = 'inline-block';
                    }
                },
                onNext: function (tour) {
                    if (tour._current === tour._options.steps.length - 1) {
                        tour.end();
                        return false;
                    }
                },
                onPrev: function (tour) {
                    if (tour._current === 0) {
                        return false;
                    }
                },
                template: `
                    <div class='popover tour'>
                        <div class='arrow'></div>
                        <h3 class='popover-header'></h3>
                        <div class='popover-body'></div>
                        <div class='popover-navigation'>
                            <div class='btn-group'>
                                <button class='btn btn-sm btn-default' data-role='prev'>
                                    « Ant
                                </button>
                                <button class='btn btn-sm btn-default' data-role='next'>
                                    <span>Sig »</span>
                                </button>
                            </div>
                            <button class='btn btn-sm btn-default' data-role='end' id='finish-tour-btn'>
                                Terminar
                            </button>
                            <button 
                                class='btn btn-sm btn-default btn-restart' 
                                id='tour-restart-btn'
                                type='button'
                            >
                                Reiniciar
                            </button>
                        </div>
                    </div>
                `,
                onEnd: function (tour) {
                    const tourCell = document.querySelector('.tour-highlighted-cell');
                    if (tourCell) {
                        tourCell.classList.remove('tour-highlighted-cell');
                        tourCell.classList.remove('highlight'); // Remover también la selección
                    }

                    // Asegurarnos de que no queden celdas seleccionadas
                    const highlightedCells = document.querySelectorAll('.jexcel tbody td.highlight');
                    highlightedCells.forEach(cell => {
                        cell.classList.remove('highlight');
                    });

                    // Si estás usando jexcel directamente, también puedes usar:
                    if (spreadsheetRef.current && spreadsheetRef.current.jexcel) {
                        spreadsheetRef.current.jexcel.resetSelection();
                    }

                    if (tourActive) {
                        setShowEndModal(true);
                    }
                    toggleSidebar();
                    setTourActive(false);
                },
                onStop: function () {
                    const tourCell = document.querySelector('.tour-highlighted-cell');
                    if (tourCell) {
                        tourCell.classList.remove('tour-highlighted-cell');
                        tourCell.classList.remove('highlight'); // Remover también la selección
                    }

                    // Asegurarnos de que no queden celdas seleccionadas
                    const highlightedCells = document.querySelectorAll('.jexcel tbody td.highlight');
                    highlightedCells.forEach(cell => {
                        cell.classList.remove('highlight');
                    });

                    // Si estás usando jexcel directamente, también puedes usar:
                    if (spreadsheetRef.current && spreadsheetRef.current.jexcel) {
                        spreadsheetRef.current.jexcel.resetSelection();
                    }
                }
            });

            document.addEventListener('click', (e) => {
                if (e.target && e.target.id === 'finish-tour-btn') {
                    skipTour()
                }
            })

            document.addEventListener('click', (e) => {
                if (e.target && e.target.id === 'tour-restart-btn') {
                    resetTour();
                }
            });

            // Guardar referencia
            tourRef.current = tour;
            window.tour = tour;
            // Iniciar el tour
            requestAnimationFrame(() => {
                tour.restart();
            });

        } catch (error) {
            console.error('Error initializing tour:', error);
        }
    };


    const setColumnHeaders = (startCol, startRow, headers) => {
        if (!spreadsheetRef.current?.jexcel) return;

        headers.forEach((header, index) => {
            const x = startCol + index;
            spreadsheetRef.current.jexcel.setValueFromCoords(x, startRow, header, true);

            // Aplicar estilos
            const cell = document.querySelector(`.jexcel tbody td[data-x="${x}"][data-y="${startRow}"]`);
            if (cell) {
                cell.style.fontWeight = 'bold';
                cell.style.backgroundColor = '#f5f5f5';
            }
        });
    };


    const setData = () => {
        if (!spreadsheetRef.current?.jexcel) return;
        const data = ['Nickname', 'Fecha Inicio', 'Fecha Final']

        data.forEach((header, index) => {
            const x = 0;
            const y = 0 + index;
            spreadsheetRef.current.jexcel.setValueFromCoords(x, y, header, true);
        });
    };



    useEffect(() => {
        return () => {
            if (tourRef.current) {
                tourRef.current.end();
                tourRef.current = null;
            }
        };
    }, []);



    React.useEffect(() => {
        if (onInitTour) {
            onInitTour(initTour);
        }
    }, [onInitTour]);



    useEffect(() => {
        if (spreadsheetRef.current) {

            // Registrar funciones personalizadas
            if (typeof jspreadsheet.current === 'undefined') {
                jspreadsheet.current = {};
            }

            initSpreadsheetFunctions()

            // Inicializar la hoja de cálculo
            jssRef.current = jspreadsheet(spreadsheetRef.current, {
                minDimensions: [20, 50],
                defaultColWidth: 100,
                wordWrap: false,
                tableOverflow: true,
                tableWidth: '100%',
                tableHeight: '100%',
                loadingSpin: true,
                showFormulaValue: false,
                parseFormulas: true,
                autoCasting: false,
                parsing: false,
                style: {
                    'B1': 'background-color: #FFF9C4; font-weight: bold;',
                    'B2': 'background-color: #FFF9C4; font-weight: bold;',
                    'B3': 'background-color: #FFF9C4; font-weight: bold;'
                },
                columns: Array(26).fill(null).map((_, index) => {
                    let width = 370; // Ancho predeterminado

                    if (index >= 1 && index <= 8) {
                        width = 370;
                    }

                    return {
                        type: 'text',
                        title: String.fromCharCode(65 + index), // Letras A, B, C, etc.
                        width: width,
                        overflow: 'hidden',
                        wordWrap: false,
                    };
                }),
                onbeforechange: function (instance, cell, x, y, value) {

                    if (!value || value === '') {
                        clearRelatedCells(x, y, instance);
                        return value;
                    }

                    // Si es una fórmula personalizada SHEETSELLER
                    if (typeof value === 'string' && value.startsWith('=SHEETSELLER_')) {
                        // Limpiar celdas anteriores antes de aplicar la nueva fórmula
                        clearRelatedCells(x, y, instance);
                        return value;
                    }

                    // Verificar si es una fórmula nativa
                    if (typeof value === 'string' && value.startsWith('=')) {
                        const isNativeFormula = ['SUM', 'AVERAGE', 'COUNT', 'MAX', 'MIN']
                            .some(formula => value.toUpperCase().startsWith(`=${formula}`));

                        if (isNativeFormula) {

                            // Extraer el rango de la fórmula (ejemplo: F6:F10)
                            const rangeMatch = value.match(/([A-Z]+[0-9]+):([A-Z]+[0-9]+)/);
                            if (rangeMatch) {
                                try {
                                    const [startCell, endCell] = rangeMatch[0].split(':');
                                    const [startCol, startRow] = getCellCoordinates(startCell);
                                    const [endCol, endRow] = getCellCoordinates(endCell);

                                    // Obtener los valores de las celdas
                                    const values = [];

                                    // Iterar sobre el rango de celdas
                                    for (let i = startRow; i <= endRow; i++) {
                                        for (let j = startCol; j <= endCol; j++) {
                                            // Obtener el valor de la celda
                                            const cellElement = spreadsheetRef.current.jexcel.getValueFromCoords(j, i, true);
                                            if (cellElement) {
                                                values.push(parseFloat(cellElement));
                                            }
                                        }
                                    }

                                    if (values.length > 0) {
                                        // Reconstruir la fórmula con los valores
                                        const formula = value.split('(')[0]; // Obtener =SUM, =AVERAGE, etc.
                                        const newFormula = `${formula}(${values.join(',')})`;
                                        return newFormula;
                                    }
                                } catch (error) {
                                    console.error('Error al procesar la fórmula:', error);
                                }
                            }
                        }
                    }


                    // Verificar si es una fórmula personalizada
                    /*const formulaId = `${x},${y}`;
                    if (window.formulaCells[formulaId]) {
                        window.formulaCells[formulaId].forEach(coord => {
                            try {
                                // Usar globalSpreadsheet en lugar de instance
                                globalSpreadsheet.setValueFromCoords(coord.x, coord.y, '');
                            } catch (e) {
                                // Intentar con setData si setValueFromCoords falla
                                try {
                                    globalSpreadsheet.setData(coord.x, coord.y, '');
                                } catch (e2) {
                                    console.error('Error al intentar método alternativo:', e2);
                                }
                            }
                        });
                        delete window.formulaCells[formulaId];
                    }*/
                    return value;
                },
                onchange: function (instance, cell, x, y, value) {
                    // Solo necesitamos manejar la limpieza si el valor ha sido eliminado
                    if (!value || value === '') {
                        clearRelatedCells(x, y, instance);
                    }
                    /*const formulaId = `${x},${y}`;

                    if (window.formulaCells[formulaId]) {
                        window.formulaCells[formulaId].forEach(coord => {
                            globalSpreadsheet.setValue(coord.x, coord.y, '');
                        });
                        delete window.formulaCells[formulaId];
                    }*/
                },
                updateTable: function (instance, cell, col, row, val, label, cellName) {
                    // Mantener la función updateTable si es necesaria
                }
            });

            // Establecer los encabezados de las columnas
            setColumnHeaders(1, 4, columnHeaders);
            setData();
            globalSpreadsheet = jssRef.current;
        }

        // Ensure the backdrop color is set when the component is mounted
        const setBackdropColor = () => {
            const backdrop = document.querySelector('.tour-backdrop');
            if (backdrop) {
                backdrop.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
            }
        };

        // Run the function on DOMContentLoaded
        document.addEventListener('DOMContentLoaded', setBackdropColor);

        return () => {
            if (jssRef.current && jssRef.current.destroy) {
                jssRef.current.destroy();
                globalSpreadsheet = null;
            }
            if (tourRef.current) {
                tourRef.current.end();
            }
        };
    }, [spreadsheetRef.current]);



    useEffect(() => {
        return () => {
            // Limpiar el registro de celdas cuando se desmonta el componente
            window.formulaCells = {};
        };
    }, []);



    return (
        <div className={`spreadsheet-area ${isSidebarVisible ? 'with-sidebar' : 'full-width'}`}>
            <div ref={spreadsheetRef} />
            <TourModals
                showWelcomeModal={showWelcomeModal}
                setShowWelcomeModal={setShowWelcomeModal}
                showEndModal={showEndModal}
                setShowEndModal={setShowEndModal}
                toggleSidebar={toggleSidebar}
                startTour={() => {
                    setShowWelcomeModal(false);
                    initTour();
                }}
                skipTour={skipTour}
            />
        </div>
    );
}