import * as React from 'react';
import { Flex, Button, Text } from '@radix-ui/themes';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { SparklesIcon, ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/solid';
import '../../styles/Header.css'

const Header = () => {
  const navigate = useNavigate();
  const [hoveredButton, setHoveredButton] = React.useState(null);
  const location = useLocation();

  const buttonStyle = (buttonName) => ({
    color: 'white',
    position: 'relative',
    fontSize: '1rem',
    padding: '12px 12px',
    transition: 'all 0.3s ease',
    backgroundColor: hoveredButton === buttonName ? 'rgba(255,255,255,0.1)' : 'transparent',
    cursor: 'pointer',
    borderRadius: '4px',
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: '0',
      left: '50%',
      width: (hoveredButton === buttonName || location.pathname === `/${buttonName.toLowerCase().replace(/\s+/g, '-')}`) ? '100%' : '0',
      height: '2px',
      backgroundColor: '#4CAF50',
      transition: 'all 0.3s ease',
      transform: 'translateX(-50%)'
    }
  });

  const navItems = [
    { name: 'Inicio', path: '/' },
    //{ name: 'Plantillas', path: '/templates' },
    { name: 'Precios', path: '/pricing' },
    { name: 'Casos de éxito', path: '/casos-de-exito' },
    { name: 'Docs', path: '/docs/' }
  ];

  const handleDocsClick = () => {
    const newPath = '/docs';
    window.location.href = newPath;
  };

  return (
    <Flex justify="between" align="center" py="2" px="6" style={{
      backgroundColor: '#1E293B',
      color: 'white',
      position: 'sticky',
      top: 0,
      zIndex: 1000
    }}>
      <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
        <Flex align="center" gap="2">
          <div style={{
            backgroundColor: '#4CAF50',
            padding: '8px',
            borderRadius: '4px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <Text style={{ fontSize: '1.2rem', fontWeight: 'bold', color: 'white' }}>SS</Text>
          </div>
          <Text style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>SheetSeller</Text>
        </Flex>
      </Link>
      <Flex gap="4">
        {navItems.map((item) => {
          if (item.name === 'Docs') {
            return (
              <Button
                key={item.name}
                variant="ghost"
                style={buttonStyle(item.name)}
                onClick={handleDocsClick} // Cambiamos aquí
                onMouseEnter={() => setHoveredButton(item.name)}
                onMouseLeave={() => setHoveredButton(null)}
              >
                {item.name}
              </Button>
            );
          }

          return (
            <Link key={item.name} to={item.path} style={{ textDecoration: 'none' }}>
              <Button
                variant="ghost"
                style={buttonStyle(item.name)}
                onMouseEnter={() => setHoveredButton(item.name)}
                onMouseLeave={() => setHoveredButton(null)}
              >
                {item.name}
              </Button>
            </Link>
          );
        })}


        <Link 
          to="#" 
          style={{ 
            textDecoration: 'none',
            position: 'relative'
          }}
          onClick={(e) => {
            e.preventDefault(); // Evita el comportamiento por defecto del enlace
            window.location.href = process.env.REACT_APP_BOT; // Redirige a la URL definida en la variable de entorno
          }}
        >
          <Button
            variant="ghost"
            className="chat-button"
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              padding: '8px 16px',
              background: 'linear-gradient(135deg, rgba(255,255,255,0.1), rgba(255,255,255,0.05))',
              borderRadius: '20px',
              color: 'white',
              border: '1px solid rgba(255,255,255,0.1)',
              position: 'relative',
              overflow: 'hidden'
            }}
          >
            <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
              <ChatBubbleBottomCenterTextIcon style={{ width: '24px', height: '24px', color: '#fff' }} />
              <SparklesIcon 
                style={{ 
                  position: 'absolute',
                  width: '16px',
                  height: '16px',
                  color: '#FFD700',
                  right: '-8px',
                  top: '-8px'
                }}
                className="sparkle-icon"
              />
            </div>
            <span>FormuBot IA</span>
          </Button>
        </Link>



        <Button className='button-demo' onClick={() => navigate("/demo")}>
          Probar Demo
        </Button>
      </Flex>
    </Flex>
  );
};

export default Header;