import '../../styles/Testimonials.css'

function Testomonials() {
    const examples = [
        {
            id: 1,
            quote: `"La automatización con SheetSeller transformó nuestra operación. Redujimos el tiempo de análisis de datos de 3 horas diarias a solo 15 minutos. Ahora podemos enfocarnos en crecer el negocio."`,
            author: "Juan Martínez",
            position: "Fundador, Techno Shop MX",
            stats: ["📊 95% menos tiempo en gestión de datos", "⭐️ 6 meses usando la plataforma"],
            image: "https://unavatar.io/github/mdo"
        },
        {
            id: 2,
            quote: `"Dejamos atrás las hojas de cálculo manuales. La sincronización automática nos permite tener todo actualizado sin esfuerzo. Lo que antes tomaba medio día, ahora es instantáneo."`,
            author: "Patricia Ruiz",
            position: "Gerente de Operaciones, Gadgets Online",
            stats: ["⚡️ Actualización en tiempo real", "⭐️ 8 meses usando la plataforma"],
            image: "https://writestylesonline.com/wp-content/uploads/2018/11/Three-Statistics-That-Will-Make-You-Rethink-Your-Professional-Profile-Picture.jpg"
        },
        {
            id: 3,
            quote: `"Desde que implementamos SheetSeller, nuestras ventas crecieron 42% en el primer trimestre. La visibilidad en tiempo real nos permite tomar decisiones más inteligentes sobre precios y stock."`,
            author: "Miguel Ángel Soto",
            position: "CEO, ImportaMX",
            stats: ["📈 +42% en ventas trimestrales", "⭐️ 4 meses usando la plataforma"],
            image: "https://unavatar.io/github/midudev"
        },
        {
            id: 4,
            quote: `"La plataforma nos ayudó a identificar nuestros productos más rentables y optimizar nuestro catálogo. Aumentamos nuestro margen de ganancia en un 28% en solo 2 meses."`,
            author: "Laura Vega",
            position: "Directora Comercial, TechStyle",
            stats: ["💰 28% incremento en rentabilidad", "⭐️ 5 meses usando la plataforma"],
            image: "https://i.pinimg.com/736x/08/16/eb/0816ebc536f6ced30e394c3f46e36ef5.jpg"
        },
        {
            id: 5,
            quote: `"La información en tiempo real nos permitió reducir nuestro inventario en un 35% sin afectar las ventas. Las decisiones basadas en datos han sido clave para optimizar nuestra operación."`,
            author: "Roberto Campos",
            position: "Director de Operaciones, Digital Import",
            stats: ["📦 35% reducción en inventario", "⭐️ 7 meses usando la plataforma"],
            image: "https://plus.unsplash.com/premium_photo-1689977968861-9c91dbb16049?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8cHJvZmlsZSUyMHBpY3R1cmV8ZW58MHx8MHx8fDA%3D"
        },
        {
            id: 6,
            quote: `"Gracias a los dashboards en tiempo real, identificamos patrones de venta que nos ayudaron a reducir costos operativos en un 23%. La claridad en los datos hace toda la diferencia."`,
            author: "Ana María Torres",
            position: "Fundadora, TechMarket MX",
            stats: ["💡 23% reducción en costos", "⭐️ 3 meses usando la plataforma"],
            image: "https://images.unsplash.com/photo-1506863530036-1efeddceb993?q=80&w=1944&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        }
    ]

    return (
        <div className="container">
            <div className="cards-grid">
                {examples.map((testimonial) => (
                    <div className="card" key={testimonial.id}>
                        <div className="card-header">
                            <div className="verified-badge">
                                ✓ Verificado
                            </div>
                            <img src={testimonial.image} alt={testimonial.author} className='profile-image' />
                        </div>
                        <div className="card-content">
                            <p className="quote">{testimonial.quote}</p>

                            <div className="author-info">
                                <h3 className="author-name">{testimonial.author}</h3>
                                <p className="author-position">{testimonial.position}</p>
                            </div>

                            <div className="stats">
                                {testimonial.stats.map((stat, index) => (
                                    <p className="stat-item" key={index}>{stat}</p>
                                ))}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Testomonials