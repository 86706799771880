import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Theme } from '@radix-ui/themes';
import ScrollToTop from './components/ScrollToTop'
import LandingPage from './pages/LandingPage';
import PricingPage from './pages/PricingPage';
import Templates from './pages/Templates';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import TemplatePage from './components/templates/TemplatePage';
import Reviews from './pages/Reviews';
import '@radix-ui/themes/styles.css';
//import DemoPage from './pages/Demo';
import { useLocation } from 'react-router-dom';
import { Demo } from './pages/Demo';



//Cargamos recursos necesarios para el demo
import 'jspreadsheet-ce/dist/jspreadsheet.css';
import 'jsuites/dist/jsuites.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-tour/build/css/bootstrap-tour.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './styles/tour.css';


function App() {
  return (
    <Router basename='/landing'>
      <ScrollToTop />
      <Theme>
        <div style={{ backgroundColor: '#1E293B', minHeight: '100vh' }}>
          <Header />
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/pricing" element={<PricingPage />} />
            <Route path="/casos-de-exito" element={<Reviews />} />
            <Route path="/templates" element={<Templates />} />
            <Route path="/template/:slug" element={<TemplatePage />} />
            <Route path="/demo" element={<Demo />} />
            {/* Agrega más rutas aquí según sea necesario */}
          </Routes>
          <ConditionalFooter />
        </div>
      </Theme>
    </Router>
  );
}

function ConditionalFooter() {
  const location = useLocation();
  // Especifica las rutas donde NO quieres renderizar el Footer
  const noFooterRoutes = ['/demo'];
  return !noFooterRoutes.includes(location.pathname) ? <Footer /> : null;
}

export default App;