import React from 'react';
import Slider from 'react-slick';
import ReactPlayer from 'react-player';
import { Card, Typography, Box, Chip, IconButton } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Componentes para las flechas personalizadas
const NextArrow = ({ onClick }) => (
    <IconButton
        onClick={onClick}
        sx={{
            position: 'absolute',
            right: '-50px',
            top: '50%',
            transform: 'translateY(-50%)',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
            },
            zIndex: 1,
        }}
    >
        <ArrowForwardIosIcon />
    </IconButton>
);

const PrevArrow = ({ onClick }) => (
    <IconButton
        onClick={onClick}
        sx={{
            position: 'absolute',
            left: '-50px',
            top: '50%',
            transform: 'translateY(-50%)',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
            },
            zIndex: 1,
        }}
    >
        <ArrowBackIosIcon />
    </IconButton>
);

const VideoCarousel = () => {
    const videos = [
        {
            id: 1,
            title: "De 4 horas en Excel a 5 minutos con SheetSeller",
            seller: "María González",
            category: "Vendedora de Tecnología",
            duration: "1:45",
            url: "https://www.youtube.com/watch?v=dQw4w9WgXcQ"
        },
        {
            id: 2,
            title: "Cómo triplicamos ventas con datos en tiempo real",
            seller: "Carlos Pérez",
            category: "Tienda de Deportes",
            duration: "1:30",
            url: "https://www.youtube.com/watch?v=dQw4w9WgXcQ"
        },
        {
            id: 3,
            title: "Mi experiencia automatizando mi tienda ML",
            seller: "Ana Martínez",
            category: "Moda y Accesorios",
            duration: "2:00",
            url: "https://www.youtube.com/watch?v=dQw4w9WgXcQ"
        }
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        dotsClass: "slick-dots custom-dots",
    };

    return (
        <Box sx={{ maxWidth: '1000px', margin: '0 auto', padding: '40px 20px' }}>
            {/* Contenedor del Carousel */}
            <Box
                sx={{
                    position: 'relative',
                    '& .custom-dots': {
                        bottom: '-30px',
                        '& li button:before': {
                            fontSize: '12px',
                            color: '#c0c0c0', // Color de los puntos inactivos
                            opacity: 0.5, // Opacidad de los puntos inactivos
                        },
                        '& li.slick-active button:before': {
                            color: '#03a725', // Color del punto activo (el mismo verde)
                            opacity: 1, // Opacidad del punto activo
                        },
                        '& li button:hover:before': {
                            color: '#03a725', // Color al hacer hover
                        }
                    },
                }}
            >
                <Slider {...settings}>
                    {videos.map((video) => (
                        <div key={video.id}>
                            <Card
                                sx={{
                                    padding: 2,
                                    margin: '0 10px',
                                    boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                                    borderRadius: '12px',
                                    transition: 'transform 0.3s ease',
                                    '&:hover': {
                                        transform: 'translateY(-5px)',
                                    },
                                }}
                            >
                                <ReactPlayer
                                    url={video.url}
                                    width="100%"
                                    height="400px"
                                    controls
                                />
                                <Box sx={{ mt: 2 }}>
                                    <Typography
                                        variant="h5"
                                        gutterBottom
                                        sx={{
                                            fontWeight: 600,
                                            fontSize: '1.2rem',
                                        }}
                                    >
                                        {video.title}
                                    </Typography>
                                    <Typography
                                        variant="subtitle1"
                                        color="text.secondary"
                                        sx={{ mb: 1 }}
                                    >
                                        {video.seller}
                                    </Typography>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        mt: 1
                                    }}>
                                        <Chip
                                            label={video.category}
                                            sx={{
                                                backgroundColor: '#03a725',
                                                color: 'white',
                                                borderRadius: '8px',
                                                '& .MuiChip-label': {
                                                    fontWeight: 500,
                                                },
                                            }}
                                        />
                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                            sx={{ fontWeight: 500 }}
                                        >
                                            Duración: {video.duration} min
                                        </Typography>
                                    </Box>
                                </Box>
                            </Card>
                        </div>
                    ))}
                </Slider>
            </Box>
        </Box>
    );
};

export default VideoCarousel;