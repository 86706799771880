import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import './styles.css';

export function TourModals({ 
    showWelcomeModal, 
    setShowWelcomeModal, 
    showEndModal, 
    setShowEndModal, 
    toggleSidebar,
    startTour,
    skipTour
}) {
    // Manejadores de eventos
    const handleStartTour = () => {
        // Primero ocultar el modal y luego iniciar el tour
        setShowWelcomeModal(false);
        // Pequeño retraso para asegurar que el modal se haya ocultado
        setTimeout(() => {
            startTour();
        }, 100);
    };

    const handleSkipTour = () => {
        skipTour();
        setShowWelcomeModal(false);
        toggleSidebar();
    };

    const handleEndTour = () => {
        setShowEndModal(false);
        toggleSidebar();
    };


    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);

    const buttonStyle = {
        backgroundColor: isHovered ? '#44a45c' : '#5bae75', // Cambia el color en hover
        borderColor: isHovered ? '#44a45c' : '#5bae75',
        color: '#FFFFFF',
        transition: 'background-color 0.3s ease', // Transición suave
    };


    return (
        <>
            <Modal 
                show={showWelcomeModal} 
                onHide={handleSkipTour}
                centered
                backdrop="static" // Previene que se cierre haciendo clic fuera
                keyboard={false} // Previene que se cierre con la tecla Esc
            >
                <Modal.Header>
                    <Modal.Title>¡Bienvenido a la demo de SheetsellerApp!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>En cuestión de algunos minutos aprenderas a utilizar algunas formulas de SheetsellerApp para obtener
                        información acerca de tus publicaciones en MercadoLibre.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button 
                        variant="secondary" 
                        onClick={handleSkipTour}
                    >
                        Saltar Tour
                    </Button>
                    <Button 
                        style={buttonStyle}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        variant="primary" 
                        onClick={handleStartTour}
                    >
                        Empezar Tour
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal 
                show={showEndModal} 
                onHide={handleEndTour}
                centered
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Tour Completed!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>You've completed the tour! Feel free to explore the spreadsheet on your own.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button 
                        variant="primary" 
                        onClick={handleEndTour}
                    >
                        Got it!
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}