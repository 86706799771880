import ReviewsHero from "../components/reviews/ReviewsHero";
import { Box, Container } from '@radix-ui/themes';
import TrustedCompanies from '../components/common/TrustedCompanies'
import ReviewsCards from "../components/reviews/ReviewsCards";
import VideoCarousel from "../components/common/VideoCarousel";
import Testomonials from "../components/reviews/Testomonials";
import SyncBanner from "../components/templates/SyncBanner";

const Reviews = () => {
  return (
    <div>
      <ReviewsHero />
      <Box py="4" style={{ backgroundColor: 'white' }}>
        <Container>
          <TrustedCompanies />
          <VideoCarousel />
          <Testomonials />
          <div style={{ marginTop: '-20px', marginBottom: '15px' }}>
            <SyncBanner />
          </div>
        </Container>
      </Box>
    </div>
  );
};


export default Reviews;