import React from 'react';
import { Box, Container, Text, Heading, Button, Flex } from '@radix-ui/themes';
import { Link } from 'react-router-dom';
import { CursorArrowIcon } from '@radix-ui/react-icons';
import styled from 'styled-components';
import './css/ReviewsHero.css';

const HeroContainer = styled(Box)`
  background-color: #0A2540;
  color: white;
  padding: 6rem 0;
  position: relative;
  overflow: hidden;
`;

const StyledContainer = styled(Container)`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;  // Alinea los elementos al inicio (izquierda)
  width: 50%;  // Opcional: para usar solo la mitad izquierda
  margin-left: 20%;
`;

const SmallHeading = styled(Text)`
  color: #4CAF50;
  font-size: 1.777rem;
  margin-bottom: 1rem;
`;

const MainHeading = styled(Heading)`
  color: white !important;
  font-size: 4.209rem !important;
  line-height: 1.2 !important;
  margin-bottom: 1.5rem !important;
`;

const Subtitle = styled(Heading)`
  color: white !important;
  font-size: 2.369rem !important;
  line-height: 1.2 !important;
  margin-bottom: 1.5rem !important;
  font-weight: normal;
`;

const Description = styled(Text)`
  color: white;
  font-size: 1.333rem;
  line-height: 1.3 !important;
  margin-bottom: 2rem;
`;



// Efecto de fondo con gradiente y overlay
const BackgroundOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at top right, rgba(0, 179, 126, 0.1), transparent 50%);
`;

const ReviewsHero = () => {
  return (
    <HeroContainer>
      {/* <BackgroundOverlay /> */}
      <StyledContainer size="3">
        <SmallHeading>
          SheetSeller
        </SmallHeading>
        <MainHeading>
          Automatiza tu Negocio en Mercado Libre
        </MainHeading>
        <Subtitle>
          Ahorra +15 horas semanales y toma decisiones más inteligentes con datos en tiempo real
        </Subtitle>
        <Description>
          ⚡ 3 minutos para comenzar<br />
          📊 Actualización automática<br />
          🔄 Integrado con Google Sheets
        </Description>
        <Flex
          gap="6"
          align="center"
          justify="start"
          style={{
            marginTop: '2rem',
            width: '100%'
          }}
        >
          <Link style={{ 'textDecoration': 'none' }} to={'/pricing'}>
            <Button
              size="4"
              className="custom-button-how"
            >
              ¡Comienza gratis ahora!
              <CursorArrowIcon style={{ marginLeft: '8px' }} />
            </Button>
          </Link>
          <Link to={'/demo'} style={{ textDecoration: 'none' }}>
            <Button
              size="4"
              className="outline-button-how"
            >
              Probar Demo
            </Button>
          </Link>
        </Flex>
      </StyledContainer>
    </HeroContainer>
  );
};

export default ReviewsHero;