import React, { useState, useCallback } from 'react';
import { SpreadsheetGrid } from '../components/demo/Spreadsheet/SpreadsheetGrid';
import { FunctionsSidebar } from '../components/demo/Sidebar/FunctionsSidebar';

import '../styles/Demo.css'; 

export function Demo() {
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);
    const [tourFunction, setTourFunction] = useState(null);


    const toggleSidebar = () => {
        setIsSidebarVisible((prevState) => true);
    };

    const handleInitTour = useCallback((initTourFunc) => {
        setTourFunction(() => initTourFunc);
    }, []);




    return (
        <div className="app-container">
            <div className="main-content">
                <SpreadsheetGrid 
                    toggleSidebar={toggleSidebar}
                    onInitTour={handleInitTour}
                    isSidebarVisible={isSidebarVisible}
                />
                {isSidebarVisible && <FunctionsSidebar onStartTour={tourFunction} />}
            </div>
        </div>
    );
}




/*import FormulaPanel from "../components/demo/FormulaPanel";
import SpreadsheetComponent from "../components/demo/Spreadsheet";


const DemoPage = () => {
  return (
    <SpreadsheetComponent/>
  )
}

export default DemoPage;*/