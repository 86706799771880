import { data } from './data'
import { globalSpreadsheet, getCellCoordinates } from '../Spreadsheet/SpreadsheetGrid';



if (typeof window.formulaCells === 'undefined') {
    window.formulaCells = {};
}

const functionDisp =
    [
        'SHEETSELLER_SKUS', 'SHEETSELLER_PUBLICACIONES', 'SHEETSELLER_ID', 'SHEETSELLER_CODIGOML', 'SHEETSELLER_PRECIO',
        'SHEETSELLER_STATUS', 'SHEETSELLER_TITULO', 'SHEETSELLER_URL', 'SHEETSELLER_STOCK', 'SHEETSELLER_ORDENES', 'SHEETSELLER_TOPVENTASUNIDADES',
        'SHEETSELLER_CATALOGO', 'SHEETSELLER_DASHBOARD'
    ]

export const functionDispLower = functionDisp.map(fn => fn.toLowerCase());

export const initSpreadsheetFunctions = () => {

    // SHEETSELLER_SKUS
    window.SHEETSELLER_SKUS = function () {
        try {
            const args = Array.from(arguments);

            /*const activeCell = document.querySelector('.jexcel tbody td.highlight');
            if (!activeCell) {
                console.log("Chavales estoy buscando homer")
                return '#ERROR: No cell selected';
            }*/

            const y = args.pop()
            const x = args.pop()

            //const x = parseInt(activeCell.getAttribute('data-x'));
            //const y = parseInt(activeCell.getAttribute('data-y'));
            //console.log("Tipo x ",  x)
            //console.log("Tipo y ",  y)


            const account = String(args[0]);
            const filter = String(args[1]);


            if (!data[account]) {
                return '#ERROR: Cuenta no encontrada';
            }

            if (!globalSpreadsheet) {
                return '#ERROR: Spreadsheet not initialized';
            }

            const publications = data[account].publicaciones;


            let skus;
            if (filter.toLowerCase() === 'todos') {
                skus = publications.map(pub => pub.sku);
            } else {
                skus = publications
                    .filter(pub => pub.sku.includes(filter))
                    .map(pub => pub.sku);
            }
            if (skus.length === 0) {
                return '#ERROR: No se encontraron SKUs';
            }


            //const formulaId = `${startX},${startY}`;
            const formulaId = `${x},${y}`;

            // Limpiar celdas anteriores sin disparar eventos
            if (window.formulaCells[formulaId]) {
                window.formulaCells[formulaId].forEach(coord => {
                    try {
                        globalSpreadsheet.setValueFromCoords(coord.x, coord.y, '', false); // Añadido false
                    } catch (e) {
                        console.error('Error clearing cell:', e);
                    }
                });
            }
            window.formulaCells[formulaId] = [];

            // Escribir nuevos valores sin disparar eventos
            skus.forEach((sku, index) => {
                if (index > 0) { // Cambiado el if para mayor claridad
                    try {
                        globalSpreadsheet.setValueFromCoords(x, y + index, sku, false); // Añadido false
                        window.formulaCells[formulaId].push({ x: x, y: y + index });
                    } catch (e) {
                        console.error('Error setting value:', e);
                    }
                }
            });
            return skus[0];

        } catch (error) {
            console.error('Error en SHEETSELLER_SKUS:', error);
            return '#ERROR: Parametros invalidos';
        }
    };




    // SHEETSELLER_PUBLICACIONES
    window.SHEETSELLER_PUBLICACIONES = function () {
        try {
            // Obtener los argumentos
            const args = Array.from(arguments);

            const startY = args.pop()
            const startX = args.pop()

            // Extraer los parámetros con valores por defecto
            const account = String(args[0] || '');
            const sku = String(args[1] || 'todos');
            const fullFilter = String(args[2] || '');
            const priceType = String(args[3] || 'base');
            const mostrarEncabezados = args[4] ? String(args[4]).toLowerCase() === 'encabezados' : false; // Nuevo parámetro opcional

            // A partir de aquí es tu código original
            if (!data[account]) {
                return '#ERROR: Cuenta no encontrada';
            }

            if (!globalSpreadsheet) {
                return '#ERROR: Spreadsheet not initialized';
            }

            let publications = data[account].publicaciones;
            let headers

            if (sku.toLowerCase() !== 'todos') {
                publications = publications.filter(pub => pub.sku.includes(sku));
            }

            if (fullFilter) {
                if (fullFilter.toLowerCase() === 'full') {
                    publications = publications.filter(pub => pub.logistic_type === 'fullfilment');
                } else if (fullFilter.toLowerCase() === 'nofull') {
                    publications = publications.filter(pub => pub.logistic_type !== 'fullfilment');
                }
            }

            if (publications.length === 0) {
                return '#ERROR: No se encontraron publicaciones';
            }

            const formulaId = `${startX},${startY}`;

            if (window.formulaCells[formulaId]) {
                window.formulaCells[formulaId].forEach(coord => {
                    try {
                        globalSpreadsheet.setValueFromCoords(coord.x, coord.y, '');
                    } catch (e) {
                        console.error('Error clearing cell:', e);
                    }
                });
            }

            window.formulaCells[formulaId] = [];

            // Agregar encabezados si se especificó "encabezados"
            if (mostrarEncabezados) {
                headers = ["ID PUBLICACION", "TITULO", "SKU", "STOCK ACTUAL", "PRECIO", "LOGISTICA", "URL", "TIPO DE PUBLICACION", "STATUS", "CODIGO ML"];
                headers.forEach((header, colIndex) => {
                    try {
                        if (colIndex === 0) return;
                        globalSpreadsheet.setValueFromCoords(startX + colIndex, startY, header);
                        window.formulaCells[formulaId].push({
                            x: startX + colIndex,
                            y: startY
                        });
                    } catch (e) {
                        console.error('Error setting header:', e);
                    }
                });
            }

            const startRow = mostrarEncabezados ? 1 : 0; // Ajustar la fila inicial según si hay encabezados

            publications.forEach((pub, rowIndex) => {
                const precio = priceType.toLowerCase() === 'promo' ? pub.promo : pub.price;
                const rowData = [
                    pub.id,
                    pub.title,
                    pub.sku,
                    pub.stock,
                    precio,
                    pub.logistic_type,
                    pub.url,
                    pub.type_publi,
                    pub.status,
                    pub.inventory_id || 'N/A'
                ];

                rowData.forEach((value, colIndex) => {
                    try {
                        if (startY === startY + rowIndex + startRow && colIndex === 0) return;

                        globalSpreadsheet.setValueFromCoords(
                            startX + colIndex,
                            startY + rowIndex + startRow,
                            value
                        );
                        window.formulaCells[formulaId].push({
                            x: startX + colIndex,
                            y: startY + rowIndex + startRow
                        });
                    } catch (e) {
                        console.error('Error setting value:', e);
                    }
                });
            });

            if (mostrarEncabezados) return headers[0]
            return publications[0].id;

        } catch (error) {
            console.error('Error en SHEETSELLER_PUBLICACIONES:', error);
            return '#ERROR: Parámetros inválidos';
        }
    };




    // SHEETSELLER_ID
    window.SHEETSELLER_ID = function () {
        try {
            // Obtener los argumentos
            const args = Array.from(arguments);

            const startY = args.pop()
            const startX = args.pop()

            // El primer argumento es la cuenta, el resto son SKUs
            const account = String(args[0] || '');
            const skus = args.slice(1).map(String);

            if (!data[account]) {
                return '#ERROR: Cuenta no encontrada';
            }

            // Filtrar SKUs vacíos o nulos
            const validSkus = skus.filter(sku => sku !== null && sku !== undefined && sku !== '');

            if (validSkus.length === 0) {
                return '#ERROR: No se proporcionaron SKUs válidos';
            }


            const formulaId = `${startX},${startY}`;

            // Limpiar resultados anteriores
            if (window.formulaCells[formulaId]) {
                window.formulaCells[formulaId].forEach(coord => {
                    globalSpreadsheet.setValueFromCoords(coord.x, coord.y, '');
                });
            }
            window.formulaCells[formulaId] = [];

            // Procesar cada SKU y encontrar sus IDs
            const results = [];
            validSkus.forEach((sku, index) => {

                const publications = data[account].publicaciones.filter(pub => pub.sku === sku);

                if (publications.length === 0) {
                    results.push({
                        sku: sku,
                        ids: [`No se encontró publicación para SKU: ${sku}`]
                    });
                } else {
                    results.push({
                        sku: sku,
                        ids: publications.map(pub => pub.id)
                    });
                }
            });

            // Escribir los resultados
            let firstCellValue = null;
            let currentRow = startY;

            results.forEach((result, index) => {
                if (index === 0) {
                    firstCellValue = result.ids[0];
                }

                result.ids.forEach((id, colIndex) => {
                    const targetX = startX + colIndex;
                    const targetY = currentRow;

                    if (!(index === 0 && colIndex === 0)) {
                        globalSpreadsheet.setValueFromCoords(targetX, targetY, id);
                        window.formulaCells[formulaId].push({
                            x: targetX,
                            y: targetY
                        });
                    }
                });

                currentRow++;
            });

            return firstCellValue;

        } catch (error) {
            console.error('Error en SHEETSELLER_ID:', error);
            return '#ERROR: ' + error.message;
        }
    };





    // SHEETSELLER_CODIGOML
    window.SHEETSELLER_CODIGOML = function () {
        try {
            const args = Array.from(arguments);

            const startY = args.pop()
            const startX = args.pop()

            // El primer argumento es la cuenta
            const account = String(args[0] || '');

            // Necesitamos determinar cuántos argumentos son SKUs y cuántos son IDs
            const totalArgs = args.length;
            const numPairs = Math.floor((totalArgs - 1) / 2);

            // Separar SKUs e IDs
            const skus = args.slice(1, numPairs + 1);
            const ids = args.slice(numPairs + 1, totalArgs);

            if (!data[account]) {
                return '#ERROR: Cuenta no encontrada';
            }

            if (skus.length !== ids.length) {
                return '#ERROR: El número de SKUs debe ser igual al número de IDs';
            }

            // Crear pares SKU-ID
            const pairs = skus.map((sku, index) => ({
                sku: String(sku).trim(),
                id: String(ids[index]).trim()
            }));

            const formulaId = `${startX},${startY}`;

            // Limpiar resultados anteriores
            if (window.formulaCells[formulaId]) {
                window.formulaCells[formulaId].forEach(coord => {
                    globalSpreadsheet.setValueFromCoords(coord.x, coord.y, '');
                });
            }
            window.formulaCells[formulaId] = [];

            // Procesar cada par SKU-ID
            const results = [];
            pairs.forEach((pair, index) => {

                const publication = data[account].publicaciones.find(
                    pub => pub.sku === pair.sku && String(pub.id) === pair.id
                );


                if (!publication) {
                    results.push({
                        sku: pair.sku,
                        code: `No se encontró publicación para SKU: ${pair.sku} con ID: ${pair.id}`
                    });
                } else {
                    results.push({
                        sku: pair.sku,
                        code: publication.inventory_id || 'N/A'
                    });
                }
            });

            // Escribir los resultados
            let firstCellValue = null;
            results.forEach((result, index) => {
                if (index === 0) {
                    firstCellValue = result.code;
                } else {
                    globalSpreadsheet.setValueFromCoords(startX, startY + index, result.code);
                    window.formulaCells[formulaId].push({
                        x: startX,
                        y: startY + index
                    });
                }
            });
            return firstCellValue;

        } catch (error) {
            console.error('Error en SHEETSELLER_CODIGOML:', error);
            return '#ERROR: ' + error.message;
        }
    };



    window.SHEETSELLER_PRECIO = function () {
        try {
            const args = Array.from(arguments);

            const startY = args.pop()
            const startX = args.pop()

            // El primer argumento es la cuenta
            const account = String(args[0] || '');

            // Determinar si el último argumento es el parámetro de precio
            const hasTypeParam = args[args.length - 1] === 'base' || args[args.length - 1] === 'promo';
            const priceType = hasTypeParam ? args[args.length - 1] : 'base';

            // Calcular el número de pares SKU-ID
            const totalArgs = hasTypeParam ? args.length - 2 : args.length - 1; // Restamos cuenta y posible tipo
            const numPairs = Math.floor(totalArgs / 2);

            // Separar SKUs e IDs
            const skus = args.slice(1, numPairs + 1);
            const ids = args.slice(numPairs + 1, hasTypeParam ? args.length - 1 : args.length);

            if (!data[account]) {
                return '#ERROR: Cuenta no encontrada';
            }

            if (skus.length !== ids.length) {
                return '#ERROR: El número de SKUs debe ser igual al número de IDs';
            }

            // Crear pares SKU-ID
            const pairs = skus.map((sku, index) => ({
                sku: String(sku).trim(),
                id: String(ids[index]).trim()
            }));


            const formulaId = `${startX},${startY}`;

            // Limpiar resultados anteriores
            if (window.formulaCells[formulaId]) {
                window.formulaCells[formulaId].forEach(coord => {
                    globalSpreadsheet.setValueFromCoords(coord.x, coord.y, '');
                });
            }
            window.formulaCells[formulaId] = [];

            // Procesar cada par SKU-ID
            const results = [];
            pairs.forEach((pair, index) => {
                const publication = data[account].publicaciones.find(
                    pub => pub.sku === pair.sku && String(pub.id) === pair.id
                );

                if (!publication) {
                    results.push({
                        sku: pair.sku,
                        price: `No se encontró publicación para SKU: ${pair.sku} con ID: ${pair.id}`
                    });
                } else {
                    const price = priceType === 'promo' && publication.promo
                        ? publication.promo
                        : publication.price;

                    results.push({
                        sku: pair.sku,
                        price: price || 'Sin precio'
                    });
                }
            });

            // Escribir los resultados
            let firstCellValue = null;
            results.forEach((result, index) => {
                if (index === 0) {
                    firstCellValue = result.price;
                } else {
                    globalSpreadsheet.setValueFromCoords(startX, startY + index, result.price);
                    window.formulaCells[formulaId].push({
                        x: startX,
                        y: startY + index
                    });
                }
            });

            return firstCellValue;
        } catch (error) {
            console.error('Error en SHEETSELLER_PRECIO:', error);
            return '#ERROR: ' + error.message;
        }
    };




    window.SHEETSELLER_STATUS = function () {
        try {
            const args = Array.from(arguments);

            const startY = args.pop()
            const startX = args.pop()

            // El primer argumento es la cuenta
            const account = String(args[0] || '');

            // Los demás argumentos son IDs
            const ids = args.slice(1).map(id => String(id).trim());

            if (!data[account]) {
                return '#ERROR: Cuenta no encontrada';
            }
            if (ids.length === 0) {
                return '#ERROR: No se proporcionaron IDs';
            }


            const formulaId = `${startX},${startY}`;

            // Limpiar resultados anteriores
            if (window.formulaCells[formulaId]) {
                window.formulaCells[formulaId].forEach(coord => {
                    globalSpreadsheet.setValueFromCoords(coord.x, coord.y, '');
                });
            }
            window.formulaCells[formulaId] = [];

            // Procesar cada ID
            const results = [];
            ids.forEach(id => {
                const publication = data[account].publicaciones.find(
                    pub => String(pub.id) === id
                );


                if (!publication) {
                    results.push({
                        id: id,
                        status: `No se encontró publicación con ID: ${id}`
                    });
                } else {
                    results.push({
                        id: id,
                        status: publication.status || 'Status desconocido'
                    });
                }
            });

            // Escribir los resultados
            let firstCellValue = null;
            results.forEach((result, index) => {
                if (index === 0) {
                    firstCellValue = result.status;
                } else {
                    globalSpreadsheet.setValueFromCoords(startX, startY + index, result.status);
                    window.formulaCells[formulaId].push({
                        x: startX,
                        y: startY + index
                    });
                }
            });

            return firstCellValue;

        } catch (error) {
            console.error('Error en SHEETSELLER_STATUS:', error);
            return '#ERROR: ' + error.message;
        }
    };




    window.SHEETSELLER_TITULO = function () {
        try {
            const args = Array.from(arguments);
            const startY = args.pop()
            const startX = args.pop()

            // El primer argumento es la cuenta
            const account = String(args[0] || '');

            // Los demás argumentos son IDs
            const ids = args.slice(1).map(id => String(id).trim());

            if (!data[account]) {
                return '#ERROR: Cuenta no encontrada';
            }

            if (ids.length === 0) {
                return '#ERROR: No se proporcionaron IDs';
            }


            const formulaId = `${startX},${startY}`;

            // Limpiar resultados anteriores
            if (window.formulaCells[formulaId]) {
                window.formulaCells[formulaId].forEach(coord => {
                    globalSpreadsheet.setValueFromCoords(coord.x, coord.y, '');
                });
            }
            window.formulaCells[formulaId] = [];

            // Procesar cada ID
            const results = [];
            ids.forEach(id => {
                const publication = data[account].publicaciones.find(
                    pub => String(pub.id) === id
                );

                if (!publication) {
                    results.push({
                        id: id,
                        title: `No se encontró publicación con ID: ${id}`
                    });
                } else {
                    results.push({
                        id: id,
                        title: publication.title || 'Sin título'
                    });
                }
            });

            // Escribir los resultados
            let firstCellValue = null;
            results.forEach((result, index) => {
                if (index === 0) {
                    firstCellValue = result.title;
                } else {
                    globalSpreadsheet.setValueFromCoords(startX, startY + index, result.title);
                    window.formulaCells[formulaId].push({
                        x: startX,
                        y: startY + index
                    });
                }
            });

            return firstCellValue;

        } catch (error) {
            console.error('Error en SHEETSELLER_TITULO:', error);
            return '#ERROR: ' + error.message;
        }
    };




    window.SHEETSELLER_URL = function () {
        try {
            const args = Array.from(arguments);

            const startY = args.pop()
            const startX = args.pop()

            // El primer argumento es la cuenta
            const account = String(args[0] || '');

            // Calcular el número de pares SKU-ID
            const totalArgs = args.length - 1; // Restamos la cuenta
            const numPairs = Math.floor(totalArgs / 2);

            // Separar SKUs e IDs
            const skus = args.slice(1, numPairs + 1);
            const ids = args.slice(numPairs + 1);

            if (!data[account]) {
                return '#ERROR: Cuenta no encontrada';
            }

            if (skus.length !== ids.length) {
                return '#ERROR: El número de SKUs debe ser igual al número de IDs';
            }

            // Crear pares SKU-ID
            const pairs = skus.map((sku, index) => ({
                sku: String(sku).trim(),
                id: String(ids[index]).trim()
            }));

            const formulaId = `${startX},${startY}`;

            // Limpiar resultados anteriores
            if (window.formulaCells[formulaId]) {
                window.formulaCells[formulaId].forEach(coord => {
                    globalSpreadsheet.setValueFromCoords(coord.x, coord.y, '');
                });
            }
            window.formulaCells[formulaId] = [];

            // Procesar cada par SKU-ID
            const results = [];
            pairs.forEach((pair, index) => {

                const publication = data[account].publicaciones.find(
                    pub => pub.sku === pair.sku && String(pub.id) === pair.id
                );

                if (!publication) {
                    results.push({
                        sku: pair.sku,
                        url: `No se encontró publicación para SKU: ${pair.sku} con ID: ${pair.id}`
                    });
                } else {
                    results.push({
                        sku: pair.sku,
                        url: publication.url || 'Sin URL'
                    });
                }
            });

            // Escribir los resultados
            let firstCellValue = null;
            results.forEach((result, index) => {
                if (index === 0) {
                    firstCellValue = result.url;
                } else {
                    globalSpreadsheet.setValueFromCoords(startX, startY + index, result.url);
                    window.formulaCells[formulaId].push({
                        x: startX,
                        y: startY + index
                    });
                }
            });

            return firstCellValue;

        } catch (error) {
            console.error('Error en SHEETSELLER_URL:', error);
            return '#ERROR: ' + error.message;
        }
    };


    window.SHEETSELLER_STOCK = function () {
        try {
            const args = Array.from(arguments);

            const startY = args.pop()
            const startX = args.pop()

            // El primer argumento es la cuenta
            const account = String(args[0] || '');

            // Calcular el número de pares SKU-ID
            const totalArgs = args.length - 1; // Restamos la cuenta
            const numPairs = Math.floor(totalArgs / 2);

            // Separar SKUs e IDs
            const skus = args.slice(1, numPairs + 1);
            const ids = args.slice(numPairs + 1);

            if (!data[account]) {
                return '#ERROR: Cuenta no encontrada';
            }

            if (skus.length !== ids.length) {
                return '#ERROR: El número de SKUs debe ser igual al número de IDs';
            }

            // Crear pares SKU-ID
            const pairs = skus.map((sku, index) => ({
                sku: String(sku).trim(),
                id: String(ids[index]).trim()
            }));


            const formulaId = `${startX},${startY}`;

            // Limpiar resultados anteriores
            if (window.formulaCells[formulaId]) {
                window.formulaCells[formulaId].forEach(coord => {
                    globalSpreadsheet.setValueFromCoords(coord.x, coord.y, '');
                });
            }
            window.formulaCells[formulaId] = [];

            // Procesar cada par SKU-ID
            const results = [];
            pairs.forEach((pair, index) => {

                const publication = data[account].publicaciones.find(
                    pub => pub.sku === pair.sku && String(pub.id) === pair.id
                );

                if (!publication) {
                    results.push({
                        sku: pair.sku,
                        stock: `No se encontró publicación para SKU: ${pair.sku} con ID: ${pair.id}`
                    });
                } else {
                    results.push({
                        sku: pair.sku,
                        stock: publication.stock || 'Sin stock'
                    });
                }
            });

            // Escribir los resultados
            let firstCellValue = null;
            results.forEach((result, index) => {
                if (index === 0) {
                    firstCellValue = result.stock;
                } else {
                    //console.log(`4. Escribiendo URL en [${startX},${startY + index}]:`, result.stock);
                    globalSpreadsheet.setValueFromCoords(startX, startY + index, result.stock);
                    window.formulaCells[formulaId].push({
                        x: startX,
                        y: startY + index
                    });
                }
            });

            return firstCellValue;

        } catch (error) {
            console.error('Error en SHEETSELLER_URL:', error);
            return '#ERROR: ' + error.message;
        }
    };




    window.SHEETSELLER_ORDENES = function () {
        try {
            const args = Array.from(arguments);

            const startY = args.pop();
            const startX = args.pop();

            // Validar número mínimo de argumentos
            if (args.length < 3) {
                return '#ERROR: Se requieren mínimo 3 argumentos: cuenta, fecha inicio, fecha fin';
            }

            // Obtener los argumentos, incluyendo el estado opcional y encabezados
            const [account, startDate, endDate, statusFilter = '', showHeaders = ''] = args.map(String);
            const mostrarEncabezados = showHeaders.toLowerCase() === 'encabezados';

            // Validar que la cuenta existe
            if (!data[account]) {
                return '#ERROR: Cuenta no encontrada';
            }

            // Validar formato de fechas
            const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
            if (!dateRegex.test(startDate) || !dateRegex.test(endDate)) {
                return '#ERROR: Formato de fecha inválido. Use YYYY-MM-DD';
            }

            // Convertir fechas a objetos Date para comparación
            const start = new Date(startDate);
            const end = new Date(endDate);

            if (isNaN(start.getTime()) || isNaN(end.getTime())) {
                return '#ERROR: Fechas inválidas';
            }

            if (end < start) {
                return '#ERROR: La fecha final debe ser posterior a la fecha inicial';
            }

            const formulaId = `${startX},${startY}`;

            // Limpiar resultados anteriores
            if (window.formulaCells[formulaId]) {
                window.formulaCells[formulaId].forEach(coord => {
                    globalSpreadsheet.setValueFromCoords(coord.x, coord.y, '');
                });
            }
            window.formulaCells[formulaId] = [];

            // Filtrar órdenes por fecha y estado
            let filteredOrders = data[account].ordenes.filter(order => {
                const orderDate = new Date(order.date_created);
                const dateFilter = orderDate >= start && orderDate <= end;

                // Si no hay filtro de estado, solo aplicar filtro de fecha
                if (!statusFilter) {
                    return dateFilter;
                }

                // Aplicar filtro de estado junto con el filtro de fecha
                const isConfirmada = statusFilter.toLowerCase() === 'confirmada';
                if (isConfirmada) {
                    return dateFilter && order.status === 'paid';
                } else {
                    return dateFilter && order.status !== 'paid';
                }
            });

            if (filteredOrders.length === 0) {
                return '#ERROR: No se encontraron órdenes con los filtros especificados';
            }

            // Definir los campos a mostrar y sus encabezados
            const headers = [
                'FECHA', 'ID ORDEN', 'TÍTULO', 'SKU', 'ID PUBLICACION',
                'UNIDADES VENDIDAS', 'PRECIO', 'ID CARRITO', 'STATUS'
            ];

            const startRow = mostrarEncabezados ? 1 : 0;

            // Escribir encabezados si se solicitan
            if (mostrarEncabezados) {
                headers.forEach((header, index) => {
                    if (index === 0) return;
                    globalSpreadsheet.setValueFromCoords(startX + index, startY, header);
                    window.formulaCells[formulaId].push({
                        x: startX + index,
                        y: startY
                    });
                });
            }

            // Escribir datos de órdenes
            filteredOrders.forEach((order, rowIndex) => {
                const rowData = [
                    order.date_created,
                    order.id,
                    order.title,
                    order.sku,
                    order.post_id,
                    order.units,
                    order.price,
                    order.cart_id,
                    order.status
                ];

                rowData.forEach((value, colIndex) => {
                    if (startY === startY + rowIndex + startRow && colIndex === 0) return;
                    globalSpreadsheet.setValueFromCoords(
                        startX + colIndex,
                        startY + rowIndex + startRow,
                        value
                    );
                    window.formulaCells[formulaId].push({
                        x: startX + colIndex,
                        y: startY + rowIndex + startRow
                    });
                });
            });

            if (mostrarEncabezados) return headers[0]
            // Retornar el valor para la primera celda
            return filteredOrders[0].date_created;

        } catch (error) {
            console.error('Error en SHEETSELLER_ORDERS:', error);
            return '#ERROR: ' + error.message;
        }
    };




    window.SHEETSELLER_TOPVENTASUNIDADES = function () {
        try {
            const args = Array.from(arguments);

            const startY = args.pop()
            const startX = args.pop()

            // Validar número mínimo de argumentos
            if (args.length < 4) {
                return '#ERROR: Se requieren mínimo 4 argumentos: cuenta, fecha inicio, fecha fin, número de top';
            }

            // Obtener los argumentos
            const [account, startDate, endDate, topN, showHeaders = ''] = args;
            const mostrarEncabezados = showHeaders.toLowerCase() === 'encabezados';
            const topNumber = parseInt(topN);

            // Validar que la cuenta existe
            if (!data[account]) {
                return '#ERROR: Cuenta no encontrada';
            }

            // Validar formato de fechas
            const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
            if (!dateRegex.test(startDate) || !dateRegex.test(endDate)) {
                return '#ERROR: Formato de fecha inválido. Use YYYY-MM-DD';
            }

            // Validar top number
            if (isNaN(topNumber) || topNumber < 1) {
                return '#ERROR: El número de top debe ser un número positivo';
            }

            // Convertir fechas a objetos Date para comparación
            const start = new Date(startDate);
            const end = new Date(endDate);

            if (isNaN(start.getTime()) || isNaN(end.getTime())) {
                return '#ERROR: Fechas inválidas';
            }

            if (end < start) {
                return '#ERROR: La fecha final debe ser posterior a la fecha inicial';
            }

            const formulaId = `${startX},${startY}`;

            // Limpiar resultados anteriores
            if (window.formulaCells[formulaId]) {
                window.formulaCells[formulaId].forEach(coord => {
                    globalSpreadsheet.setValueFromCoords(coord.x, coord.y, '');
                });
            }
            window.formulaCells[formulaId] = [];

            // Filtrar órdenes por fecha
            const filteredOrders = data[account].ordenes.filter(order => {
                const orderDate = new Date(order.date_created);
                return orderDate >= start && orderDate <= end;
            });

            // Agrupar por post_id y sumar unidades
            const salesByPost = filteredOrders.reduce((acc, order) => {
                const postId = order.post_id;
                if (!acc[postId]) {
                    acc[postId] = {
                        post_id: postId,
                        title: order.title,
                        sku: order.sku,
                        units: 0
                    };
                }
                acc[postId].units += parseInt(order.units) || 0;
                return acc;
            }, {});

            // Convertir a array y ordenar por unidades
            const sortedSales = Object.values(salesByPost)
                .sort((a, b) => b.units - a.units)
                .slice(0, topNumber);

            if (sortedSales.length === 0) {
                return '#ERROR: No se encontraron ventas en el rango de fechas especificado';
            }

            // Definir los campos a mostrar y sus encabezados
            const headers = ["ID PUBLICACION", "SKU", "TITULO", "UNIDADES VENDIDAS"];

            const startRow = mostrarEncabezados ? 1 : 0;

            // Escribir encabezados si se solicitan
            if (mostrarEncabezados) {
                headers.forEach((header, index) => {
                    if (index === 0) return;
                    globalSpreadsheet.setValueFromCoords(startX + index, startY, header);
                    window.formulaCells[formulaId].push({
                        x: startX + index,
                        y: startY
                    });
                });
            }

            // Escribir datos de ventas
            sortedSales.forEach((sale, rowIndex) => {
                const rowData = [
                    sale.post_id,
                    sale.title,
                    sale.sku,
                    sale.units
                ];

                rowData.forEach((value, colIndex) => {
                    if (startY === startY + rowIndex + startRow && colIndex === 0) return;
                    globalSpreadsheet.setValueFromCoords(
                        startX + colIndex,
                        startY + rowIndex + startRow,
                        value
                    );
                    window.formulaCells[formulaId].push({
                        x: startX + colIndex,
                        y: startY + rowIndex + startRow
                    });
                });
            });

            if (mostrarEncabezados) return headers[0]
            // Retornar el valor para la primera celda (post_id del más vendido)
            return sortedSales[0].post_id;

        } catch (error) {
            console.error('Error en SHEETSELLER_TOPVENTASUNIDADES:', error);
            return '#ERROR: ' + error.message;
        }
    };



    window.SHEETSELLER_TOPVENTASDINERO = function () {
        try {
            const args = Array.from(arguments);

            const startY = args.pop()
            const startX = args.pop()

            // Validar número mínimo de argumentos
            if (args.length < 4) {
                return '#ERROR: Se requieren mínimo 4 argumentos: cuenta, fecha inicio, fecha fin, número de top';
            }

            // Obtener los argumentos
            const [account, startDate, endDate, topN, showHeaders = ''] = args;
            const mostrarEncabezados = showHeaders.toLowerCase() === 'encabezados';
            const topNumber = parseInt(topN);

            // Validar que la cuenta existe
            if (!data[account]) {
                return '#ERROR: Cuenta no encontrada';
            }

            // Validar formato de fechas
            const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
            if (!dateRegex.test(startDate) || !dateRegex.test(endDate)) {
                return '#ERROR: Formato de fecha inválido. Use YYYY-MM-DD';
            }

            // Validar top number
            if (isNaN(topNumber) || topNumber < 1) {
                return '#ERROR: El número de top debe ser un número positivo';
            }

            // Convertir fechas a objetos Date para comparación
            const start = new Date(startDate);
            const end = new Date(endDate);

            if (isNaN(start.getTime()) || isNaN(end.getTime())) {
                return '#ERROR: Fechas inválidas';
            }

            if (end < start) {
                return '#ERROR: La fecha final debe ser posterior a la fecha inicial';
            }

            const formulaId = `${startX},${startY}`;

            // Limpiar resultados anteriores
            if (window.formulaCells[formulaId]) {
                window.formulaCells[formulaId].forEach(coord => {
                    globalSpreadsheet.setValueFromCoords(coord.x, coord.y, '');
                });
            }
            window.formulaCells[formulaId] = [];

            // Filtrar órdenes por fecha
            const filteredOrders = data[account].ordenes.filter(order => {
                const orderDate = new Date(order.date_created);
                return orderDate >= start && orderDate <= end;
            });

            // Agrupar por post_id y calcular totales
            const salesByPost = filteredOrders.reduce((acc, order) => {
                const postId = order.post_id;
                const units = parseInt(order.units) || 0;
                const price = parseFloat(order.price) || 0;
                const totalAmount = units * price;

                if (!acc[postId]) {
                    acc[postId] = {
                        post_id: postId,
                        title: order.title,
                        sku: order.sku,
                        units: 0,
                        totalAmount: 0
                    };
                }
                acc[postId].units += units;
                acc[postId].totalAmount += totalAmount;
                return acc;
            }, {});

            // Convertir a array y ordenar por monto total
            const sortedSales = Object.values(salesByPost)
                .sort((a, b) => b.totalAmount - a.totalAmount)
                .slice(0, topNumber);

            if (sortedSales.length === 0) {
                return '#ERROR: No se encontraron ventas en el rango de fechas especificado';
            }

            // Definir los campos a mostrar y sus encabezados
            const headers = ["ID PUBLICACION", "SKU", "TITULO", "UNIDADES VENDIDAS", "CANTIDAD DE DINERO"];

            const startRow = mostrarEncabezados ? 1 : 0;

            // Escribir encabezados si se solicitan
            if (mostrarEncabezados) {
                headers.forEach((header, index) => {
                    if (index === 0) return;
                    globalSpreadsheet.setValueFromCoords(startX + index, startY, header);
                    window.formulaCells[formulaId].push({
                        x: startX + index,
                        y: startY
                    });
                });
            }

            // Escribir datos de ventas
            sortedSales.forEach((sale, rowIndex) => {
                const rowData = [
                    sale.post_id,
                    sale.title,
                    sale.sku,
                    sale.units,
                    sale.totalAmount.toFixed(2) // Formatear a 2 decimales
                ];

                rowData.forEach((value, colIndex) => {
                    if (startY === startY + rowIndex + startRow && colIndex === 0) return;
                    globalSpreadsheet.setValueFromCoords(
                        startX + colIndex,
                        startY + rowIndex + startRow,
                        value
                    );
                    window.formulaCells[formulaId].push({
                        x: startX + colIndex,
                        y: startY + rowIndex + startRow
                    });
                });
            });
            if (mostrarEncabezados) return headers[0]
            // Retornar el valor para la primera celda (post_id del más vendido)
            return sortedSales[0].post_id;
        } catch (error) {
            console.error('Error en SHEETSELLER_TOPVENTASDINERO:', error);
            return '#ERROR: ' + error.message;
        }
    };



    window.SHEETSELLER_VENTASTOTALES = function () {
        try {
            const args = Array.from(arguments);
            args.pop();
            args.pop();

            // Validar número de argumentos
            if (args.length !== 4) {
                return '#ERROR: Se requieren 4 argumentos: cuenta, fecha inicio, fecha fin, tipo de órdenes';
            }

            // Obtener los argumentos
            const [account, startDate, endDate, orderType] = args.map(String);

            // Validar que la cuenta existe
            if (!data[account]) {
                return '#ERROR: Cuenta no encontrada';
            }

            // Validar formato de fechas
            const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
            if (!dateRegex.test(startDate) || !dateRegex.test(endDate)) {
                return '#ERROR: Formato de fecha inválido. Use YYYY-MM-DD';
            }

            // Convertir fechas a objetos Date para comparación
            const start = new Date(startDate);
            const end = new Date(endDate);

            if (isNaN(start.getTime()) || isNaN(end.getTime())) {
                return '#ERROR: Fechas inválidas';
            }

            if (end < start) {
                return '#ERROR: La fecha final debe ser posterior a la fecha inicial';
            }

            // Validar tipo de orden
            const validOrderTypes = ['todos', 'confirmadas', 'canceladas'];
            const normalizedOrderType = orderType.toLowerCase();
            if (!validOrderTypes.includes(normalizedOrderType)) {
                return '#ERROR: Tipo de orden inválido. Use: todos, confirmadas o canceladas';
            }

            // Filtrar órdenes por fecha y tipo
            const filteredOrders = data[account].ordenes.filter(order => {
                const orderDate = new Date(order.date_created);
                const dateFilter = orderDate >= start && orderDate <= end;

                switch (normalizedOrderType) {
                    case 'todos':
                        return dateFilter;
                    case 'confirmadas':
                        return dateFilter && order.status === 'paid';
                    case 'canceladas':
                        return dateFilter && order.status !== 'paid';
                    default:
                        return false;
                }
            });

            if (filteredOrders.length === 0) {
                return '#ERROR: No se encontraron órdenes con los filtros especificados';
            }

            // Calcular el total de ventas
            const totalVentas = filteredOrders.reduce((total, order) => {
                const orderTotal = order.units * order.price;
                return total + orderTotal;
            }, 0);

            // Formatear el resultado como moneda
            const formattedTotal = new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN'
            }).format(totalVentas);

            return formattedTotal;

        } catch (error) {
            console.error('Error en SHEETSELLER_VENTASTOTALES:', error);
            return '#ERROR: ' + error.message;
        }
    };

    window.SHEETSELLER_DEVOLUCIONES = function () {
        try {
            const args = Array.from(arguments);
            const startY = args.pop();
            const startX = args.pop();

            // Validar número mínimo de argumentos
            if (args.length < 4) {
                return '#ERROR: Se requieren mínimo 4 argumentos: cuenta, fecha inicio, fecha fin, id(s) o "todos"';
            }

            const account = String(args[0]);
            const startDate = String(args[1]);
            const endDate = String(args[2]);

            // Verificar si el último argumento es "encabezados"
            const lastArg = args[args.length - 1];
            const mostrarEncabezados = lastArg.toLowerCase() === 'encabezados';

            // Los IDs serán todos los argumentos restantes, excluyendo "encabezados" si está presente
            const ids = mostrarEncabezados ? args.slice(3, -1) : args.slice(3);
            const idFilter = ids.join(','); // Unimos todos los IDs con comas

            // Validar que la cuenta existe
            if (!data[account]) {
                return '#ERROR: Cuenta no encontrada';
            }

            // Validar formato de fechas
            const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
            if (!dateRegex.test(startDate) || !dateRegex.test(endDate)) {
                return '#ERROR: Formato de fecha inválido. Use YYYY-MM-DD';
            }

            // Convertir fechas a objetos Date para comparación
            const start = new Date(startDate);
            const end = new Date(endDate);

            if (isNaN(start.getTime()) || isNaN(end.getTime())) {
                return '#ERROR: Fechas inválidas';
            }

            if (end < start) {
                return '#ERROR: La fecha final debe ser posterior a la fecha inicial';
            }

            const formulaId = `${startX},${startY}`;

            // Limpiar resultados anteriores
            if (window.formulaCells[formulaId]) {
                window.formulaCells[formulaId].forEach(coord => {
                    globalSpreadsheet.setValueFromCoords(coord.x, coord.y, '');
                });
            }
            window.formulaCells[formulaId] = [];

            // Filtrar órdenes por fecha y estado (solo devoluciones)
            let filteredOrders = data[account].ordenes.filter(order => {
                const orderDate = new Date(order.date_created);
                return orderDate >= start &&
                    orderDate <= end &&
                    order.status !== 'paid';
            });

            // Convertir el string de IDs en un array
            let validIds;
            if (idFilter.toLowerCase() === 'todos') {
                validIds = new Set(filteredOrders.map(order => order.post_id));
            } else {
                // Si los IDs vienen como argumentos separados
                if (Array.isArray(idFilter)) {
                    validIds = new Set(idFilter);
                }
                // Si es un string con IDs separados por coma
                else if (typeof idFilter === 'string' && idFilter.includes(',')) {
                    validIds = new Set(idFilter.split(',').map(id => id.trim()));
                }
                // Si es un rango de celdas
                else if (typeof idFilter === 'string' && idFilter.includes(':')) {
                    validIds = new Set();
                    const [startCell, endCell] = idFilter.split(':');
                    const [startCol, startRow] = getCellCoordinates(startCell);
                    const [endCol, endRow] = getCellCoordinates(endCell);

                    for (let i = startRow; i <= endRow; i++) {
                        for (let j = startCol; j <= endCol; j++) {
                            const id = globalSpreadsheet.getValueFromCoords(j, i);
                            if (id) validIds.add(id.toString().trim());
                        }
                    }
                }
                // Si es un único ID
                else {
                    validIds = new Set([idFilter.trim()]);
                }
            }

            // Filtrar órdenes por los IDs válidos
            if (idFilter.toLowerCase() !== 'todos') {
                filteredOrders = filteredOrders.filter(order => validIds.has(order.post_id));
            }

            if (filteredOrders.length === 0) {
                return '#ERROR: No se encontraron devoluciones con los filtros especificados';
            }

            // Agrupar por id y sku
            const groupedReturns = {};
            filteredOrders.forEach(order => {
                const key = `${order.post_id}-${order.sku}`;
                if (!groupedReturns[key]) {
                    groupedReturns[key] = {
                        id: order.post_id,
                        sku: order.sku,
                        units: 0,
                        title: order.title
                    };
                }
                groupedReturns[key].units += order.units;
            });

            // Convertir a array y ordenar
            const returnsArray = Object.values(groupedReturns)
                .sort((a, b) => {
                    if (a.id !== b.id) {
                        return Array.from(validIds).indexOf(a.id) - Array.from(validIds).indexOf(b.id);
                    }
                    return b.units - a.units;
                });

            const startRow = mostrarEncabezados ? 1 : 0;

            // Escribir encabezados si se solicitan
            const headers = ["ID PUBLICACION", "SKU", "UNIDADES DEVUELTAS", "TITULO"];
            if (mostrarEncabezados) {
                headers.forEach((header, index) => {
                    if (index === 0) return;
                    globalSpreadsheet.setValueFromCoords(startX + index, startY, header);
                    window.formulaCells[formulaId].push({
                        x: startX + index,
                        y: startY
                    });
                });
            }

            // Escribir datos
            returnsArray.forEach((item, rowIndex) => {
                const rowData = [item.id, item.sku, item.units, item.title];
                rowData.forEach((value, colIndex) => {
                    if (startY === startY + rowIndex + startRow && colIndex === 0) return;
                    globalSpreadsheet.setValueFromCoords(
                        startX + colIndex,
                        startY + rowIndex + startRow,
                        value
                    );
                    window.formulaCells[formulaId].push({
                        x: startX + colIndex,
                        y: startY + rowIndex + startRow
                    });
                });
            });
            if (mostrarEncabezados) return headers[0]
            return returnsArray[0].id;

        } catch (error) {
            console.error('Error en SHEETSELLER_DEVOLUCIONES:', error);
            return '#ERROR: ' + error.message;
        }
    };



    window.SHEETSELLER_RETIROS = function () {
        try {
            const args = Array.from(arguments);
            const startY = args.pop();
            const startX = args.pop();

            // Validar número mínimo de argumentos
            if (args.length < 3) {
                return '#ERROR: Se requieren mínimo 3 argumentos: cuenta, fecha inicio, fecha fin';
            }

            const [account, startDate, endDate, showHeaders = ''] = args.map(String);
            const mostrarEncabezados = showHeaders.toLowerCase() === 'encabezados';

            // Validar que la cuenta existe
            if (!data[account]) {
                return '#ERROR: Cuenta no encontrada';
            }

            // Validar formato de fechas
            const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
            if (!dateRegex.test(startDate) || !dateRegex.test(endDate)) {
                return '#ERROR: Formato de fecha inválido. Use YYYY-MM-DD';
            }

            // Convertir fechas a objetos Date para comparación
            const start = new Date(startDate);
            const end = new Date(endDate);

            if (isNaN(start.getTime()) || isNaN(end.getTime())) {
                return '#ERROR: Fechas inválidas';
            }

            if (end < start) {
                return '#ERROR: La fecha final debe ser posterior a la fecha inicial';
            }

            const formulaId = `${startX},${startY}`;

            // Limpiar resultados anteriores
            if (window.formulaCells[formulaId]) {
                window.formulaCells[formulaId].forEach(coord => {
                    globalSpreadsheet.setValueFromCoords(coord.x, coord.y, '');
                });
            }
            window.formulaCells[formulaId] = [];

            // Filtrar retiros por fecha
            const filteredRetiros = data[account].retiros.filter(retiro => {
                const retiroDate = new Date(retiro.date_created);
                return retiroDate >= start && retiroDate <= end;
            });

            if (filteredRetiros.length === 0) {
                return '#ERROR: No se encontraron retiros en el rango de fechas especificado';
            }

            // Ordenar retiros primero por ID principal y luego por fecha
            const sortedRetiros = filteredRetiros.sort((a, b) => {
                if (a.id !== b.id) {
                    return a.id < b.id ? -1 : 1;
                }
                return new Date(a.date_created) - new Date(b.date_created);
            });

            const startRow = mostrarEncabezados ? 1 : 0;

            // Definir los encabezados
            const headers = [
                "ID PRINCIPAL RETIRO", "ID SECUNDARIO RETIRO", "CODIGO ML", "ID PUBLICACION", "SKU", "TITULO", "UNIDADES SOLICITADAS", "FECHA DE CREACIÓN", "FECHA DE ENTREGA"
            ];

            // Escribir encabezados si se solicitan
            if (mostrarEncabezados) {
                headers.forEach((header, index) => {
                    if (index === 0) return;
                    globalSpreadsheet.setValueFromCoords(startX + index, startY, header);
                    window.formulaCells[formulaId].push({
                        x: startX + index,
                        y: startY
                    });
                });
            }

            // Escribir datos
            sortedRetiros.forEach((retiro, rowIndex) => {
                const rowData = [
                    retiro.id,
                    retiro.bulto_id,
                    retiro.inventory_id  || 'N/A',
                    retiro.item_id,
                    retiro.sku,
                    retiro.title,
                    retiro.units,
                    retiro.date_created,
                    retiro.date_received || 'N/A'
                ];

                rowData.forEach((value, colIndex) => {
                    if (startY === startY + rowIndex + startRow && colIndex === 0) return;
                    globalSpreadsheet.setValueFromCoords(
                        startX + colIndex,
                        startY + rowIndex + startRow,
                        value
                    );
                    window.formulaCells[formulaId].push({
                        x: startX + colIndex,
                        y: startY + rowIndex + startRow
                    });
                });
            });

            if (mostrarEncabezados) return headers[0]
            // Retornar el ID del primer retiro
            return sortedRetiros[0].id;

        } catch (error) {
            console.error('Error en SHEETSELLER_RETIROS:', error);
            return '#ERROR: ' + error.message;
        }
    };

    window.SHEETSELLER_CATALOGO = function () {
        try {
            // Obtener los argumentos
            const args = Array.from(arguments)

            // Posición Inicial
            const startY = args.pop()
            const startX = args.pop()

            // Extraer parámetros
            const account = String(args[0] || '')
            const priceType = args[1] ? String(args[1].toLowerCase()) : 'base'
            const showHeaders = args[2] ? String(args[2].toLowerCase()) : ''
            const mostrarEncabezados = showHeaders === 'encabezados'

            // A partir de aqui es tu código original
            if (!data[account]) {
                return '#ERROR: Cuenta no encontrada'
            }

            if (!globalSpreadsheet) {
                return '#ERROR: Spreadsheet not initialized'
            }

            let publications = data[account].catalogo
            let filteredPublications;

            if (priceType) {
                filteredPublications = publications.reduce((acc, item) => {
                    const publicationData = {
                        id: item.id,
                        title: item.title,
                        sku: item.sku,
                        stock: item.stock,
                        type_publi: item.type_publi,
                        logistic_type: item.logistic_type,
                        url: item.url,
                        status: item.status,
                        inventory_id: item.inventory_id || "N/A",
                        id_catalog: item.id_catalog,
                        url_catalog: item.url_catalog,
                        envio: item.envio,
                        ventas_7: item.ventas_7,
                        ventas_15: item.ventas_15,
                        ventas_30: item.ventas_30,
                        ventas_60: item.ventas_60,
                        ventas_90: item.ventas_90,
                        ventas_365: item.ventas_365,
                        status_winner: item.status_winner,
                        percent: item.percent,
                        winner_price: item.winner_price,
                        winner_user: item.winner_user,
                        sharing_catalog: item.sharing_catalog,
                        price_to_win: item.price_to_win,
                        catalog: item.catalog
                    }

                    if (priceType === 'base') {
                        acc.push({
                            ...publicationData,
                            actual_price: item.price
                        })
                    } else if (priceType === 'promo' && item.promo !== 0) {
                        acc.push({
                            ...publicationData,
                            actual_price: item.promo
                        })
                    }
                    return acc
                }, [])
            }

            if (filteredPublications.length === 0) {
                return '#ERROR: No se encontraron publicaciones'
            }

            const formulaId = `${startX},${startY}`

            if (window.formulaCells[formulaId]) {
                window.formulaCells[formulaId].forEach(coord => {
                    try {
                        globalSpreadsheet.setValueFromCoords(coord.x, coord.y, '')
                    } catch (e) {
                        console.error('Error clearing cell:', e)
                    }
                })
            }

            window.formulaCells[formulaId] = []

            const startRow = mostrarEncabezados ? 1 : 0

            // Definir los encabezados
            const headers = [
                "ID CATALOGO", "URL CATALOGO", "ID PUBLICACION", "URL", "TITULO", "SKU", "CODIGO ML", "ENVIO A CARGO DE", "STOCK ACTUAL", "VENTAS 7 DIAS", "VENTAS 15 DIAS", "VENTAS 30 DIAS", "VENTAS 60 DIAS", "VENTAS 90 DIAS", "VENTAS 365 DIAS", "STATUS PUBLICACION CATALOGO", "STATUS WINNER CATALOGO", "% TIEMPO GANANDO CATÁLOGO SOBRE EL COMPETIDO", "PRECIO GANADOR CATALOGO", "MI PRECIO ACTUAL CATALOGO", "USUARIO GANADOR CATALOGO", "COMPARTIENDO CATALOGO CON USUARIOS", "PRICE TO WIN", "UNICO COMPETIDOR"
            ]

            // Escribir encabezados si se solicitan
            if (mostrarEncabezados) {
                headers.forEach((header, index) => {
                    if (index === 0) return;
                    globalSpreadsheet.setValueFromCoords(startX + index, startY, header)
                    window.formulaCells[formulaId].push({
                        x: startX + index,
                        y: startY
                    })
                })
            }

            filteredPublications.forEach((pub, rowIndex) => {
                const rowData = [
                    pub.id_catalog,
                    pub.url_catalog,
                    pub.id,
                    pub.url,
                    pub.title,
                    pub.sku,
                    pub.inventory_id  || 'N/A',
                    pub.envio,
                    pub.stock,
                    pub.ventas_7,
                    pub.ventas_15,
                    pub.ventas_30,
                    pub.ventas_60,
                    pub.ventas_90,
                    pub.ventas_365,
                    pub.status,
                    pub.status_winner,
                    pub.percent,
                    pub.winner_price,
                    pub.actual_price,
                    pub.winner_user,
                    pub.sharing_catalog,
                    pub.price_to_win,
                    pub.catalog
                ]

                rowData.forEach((value, colIndex) => {
                    try {
                        if (startY === startY + rowIndex + startRow && colIndex === 0) return;
                        globalSpreadsheet.setValueFromCoords(
                            startX + colIndex,
                            startY + rowIndex + startRow,
                            value
                        )
                        window.formulaCells[formulaId].push({
                            x: startX + colIndex,
                            y: startY + rowIndex + startRow
                        })
                    } catch (e) {
                        console.error('Error setting value:', e)
                    }
                })
            })
            if (mostrarEncabezados) return headers[0]
            return filteredPublications[0].id_catalog
        } catch (error) {
            console.error('Error en SHEETSELLER_CATALOGO:', error)
            return '#ERROR: ' + error.message
        }
    }

    window.SHEETSELLER_DASHBOARD = function () {
        try {
            // Obtener los argumentos
            const args = Array.from(arguments)

            const startY = args.pop()
            const startX = args.pop()

            // Extraer los parámetros con valores por defecto
            const account = String(args[0] || '')
            const fullFilter = String(args[args.length - 3] || 'full')
            const priceType = String(args[args.length - 2] || 'base')
            const headerOption = String(args[args.length - 1] || '') // Nuevo parámetro

            // Determinar si se muestran encabezados
            const showHeaders = headerOption.toLowerCase() === 'encabezados'

            // Obtener la lista de SKUs (todos los elementos entre account y fullFilter)
            const skuInputs = args.slice(1, -3)

            // Definir los encabezados
            const headers = [
                "ID PUBLICACION", "TITULO", "SKU", "STOCK ACTUAL", "PRECIO", "LOGISTICA", "URL", "STATUS", "TIPO DE PUBLICACION", "CODIGO ML", "DIAS PAUSADA", "VENTAS (7 DIAS)", "VENTAS (15 DIAS)", "(VENTAS 30 DIAS)", "VENTAS (60 DIAS)", "VENTAS (90 DIAS)", "ENVIO A CARGO DE", "COSTO DE ENVIO", "% COMISION", "COMISION", "COSTO POR UNIDAD", "TIENE CATALOGO"
            ];

            // Aquí comienza la lógica de la función
            if (!data[account]) return '#ERROR: Cuenta no encontrada'

            if (!globalSpreadsheet) return '#ERROR: Spreadsheet not initialized'

            let publications = data[account].catalogo

            // ... (resto del código de filtrado igual)

            const formulaId = `${startX},${startY}`;

            if (window.formulaCells[formulaId]) {
                window.formulaCells[formulaId].forEach(coord => {
                    try {
                        globalSpreadsheet.setValueFromCoords(coord.x, coord.y, '');
                    } catch (e) {
                        console.error('Error clearing cell:', e);
                    }
                });
            }

            window.formulaCells[formulaId] = [];

            // Agregar encabezados si se especificó "encabezados"
            if (showHeaders) {
                headers.forEach((header, colIndex) => {
                    try {
                        if (colIndex === 0) return;
                        globalSpreadsheet.setValueFromCoords(startX + colIndex, startY, header);
                        window.formulaCells[formulaId].push({
                            x: startX + colIndex,
                            y: startY
                        });
                    } catch (e) {
                        console.error('Error setting header:', e);
                    }
                });
            }

            // Ajustar el offset de las filas según si se muestran encabezados
            const rowOffset = showHeaders ? 1 : 0;

            publications.forEach((pub, rowIndex) => {
                const precio = priceType.toLowerCase() === 'promo' ? pub.promo : pub.price;
                const rowData = [
                    pub.id,
                    pub.title,
                    pub.sku,
                    pub.stock,
                    precio,
                    pub.logistic_type,
                    pub.url,
                    pub.status,
                    pub.type_publi,
                    pub.inventory_id || 'N/A',
                    pub.paused_days,
                    pub.ventas_7,
                    pub.ventas_15,
                    pub.ventas_30,
                    pub.ventas_60,
                    pub.ventas_90,
                    pub.envio,
                    pub.costo_envio,
                    `${pub.commission_percentage}%`,
                    (precio * pub.commission_percentage) / 100,
                    pub.cost_per_unit,
                    pub.catalog
                ];

                rowData.forEach((value, colIndex) => {
                    if (rowIndex === 0 && colIndex === 0 && !showHeaders) {
                        return value;
                    } else {
                        try {
                            if (startY === startY + rowIndex + rowOffset && colIndex === 0) return;
                            globalSpreadsheet.setValueFromCoords(
                                startX + colIndex,
                                startY + rowIndex + rowOffset,
                                value
                            );
                            window.formulaCells[formulaId].push({
                                x: startX + colIndex,
                                y: startY + rowIndex + rowOffset
                            });
                        } catch (e) {
                            console.error('Error setting value:', e);
                        }
                    }
                });
            });
            if (showHeaders) return headers[0]
            return publications[0].id;
        } catch (error) {
            console.error('Error en SHEETSELLER_DASHBOARD: ', error)
            return '#ERROR: ' + error.message
        }
    }

};



export const spreadsheetFunctions = {
    SHEETSELLER_SKUS: window.SHEETSELLER_SKUS,
    SHEETSELLER_ID: window.SHEETSELLER_ID,
    SHEETSELLER_CODIGOML: window.SHEETSELLER_CODIGOML,
    SHEETSELLER_PRECIO: window.SHEETSELLER_PRECIO,
    SHEETSELLER_STATUS: window.SHEETSELLER_STATUS,
    SHEETSELLER_TITULO: window.SHEETSELLER_TITULO,
    SHEETSELLER_URL: window.SHEETSELLER_URL,
    SHEETSELLER_STOCK: window.SHEETSELLER_STOCK,
    SHEETSELLER_PUBLICACIONES: window.SHEETSELLER_PUBLICACIONES,
    SHEETSELLER_ORDENES: window.SHEETSELLER_ORDENES,
    SHEETSELLER_TOPVENTASUNIDADES: window.SHEETSELLER_TOPVENTASUNIDADES,
    SHEETSELLER_TOPVENTASDINERO: window.SHEETSELLER_TOPVENTASDINERO,
    SHEETSELLER_VENTASTOTALES: window.SHEETSELLER_VENTASTOTALES,
    SHEETSELLER_DEVOLUCIONES: window.SHEETSELLER_DEVOLUCIONES,
    SHEETSELLER_RETIROS: window.SHEETSELLER_RETIROS,
    SHEETSELLER_CATALOGO: window.SHEETSELLER_CATALOGO,
    SHEETSELLER_DASHBOARD: window.SHEETSELLER_DASHBOARD

};


export function processFormula(formula) {
    try {
        if (!formula.startsWith('=')) return formula;

        const functionName = formula.substring(1).split('(')[0];
        const argsMatch = formula.match(/$(.*?)$/); // Corregido el regex

        if (!argsMatch) return '#ERROR: Invalid formula format';

        const args = argsMatch[1].split(',').map(arg => arg.trim());

        if (spreadsheetFunctions[functionName]) {
            return spreadsheetFunctions[functionName](...args);
        }

        return '#ERROR: Function not found';
    } catch (error) {
        console.error('Error processing formula:', error);
        return '#ERROR: Invalid formula';
    }
}


export function validateFormula(formula) {
    if (!formula.startsWith('=')) return false;

    const functionName = formula.substring(1).split('(')[0];
    return spreadsheetFunctions.hasOwnProperty(functionName);
}



// Mantener las funciones de utilidad
const descriptions = [
    {
        title: "SHEETSELLER_CODIGOML",
        description: "Recupera el código ml basándose en el SKU y ID de la publicación.",
        formula: "=SHEETSELLER_CODIGOML(cuenta, skus, ids)",
        ejemplo: `=SHEETSELLER_CODIGOML("ELECSHOP", "ELE-24-963", "MLM918730800")`
    },
    {
        title: "SHEETSELLER_ID",
        description: "Recupera todos los ID relacionados con un SKU específico.",
        formula: "=SHEETSELLER_ID(cuenta, skus)",
        ejemplo: `=SHEETSELLER_ID("ELECSHOP", "ELE-10-321")`
    },
    {
        title: "SHEETSELLER_PRECIO",
        description: "Recupera el precio publicado del SKU en la publicación específica. Si hay una promoción aplicada, ese será el precio que devuelve.",
        formula: "=SHEETSELLER_PRECIO(cuenta, skus, ids, base/promo)",
        ejemplo: `=SHEETSELLER_PRECIO("ELECSHOP", "COM-25-204", "MLM2174397463")`
    },
    {
        title: "SHEETSELLER_PUBLICACIONES",
        description: `Obtiene los detalles generales de todos los ítems basándose en el SKU. El tercer parámetro es "todos", que recupera todos los ítems. El cuarto parámetro puede ser "full" o "nofull" si quieres filtrar las publicaciones. Si queda vacío, no filtrará y traerá todas las publicaciones sin distinguir logística. También se cuenta con el parámetro opcional llamado encabezados, que se encarga de mostrar los nombres de cada columna.`,
        formula: "=SHEETSELLER_PUBLICACIONES(cuenta, sku, filtro full/nofull, base/promo, encabezados)",
        ejemplo: `=SHEETSELLER_PUBLICACIONES("ELECSHOP", "todos", "full", "promo", "encabezados")`
    },
    {
        title: "SHEETSELLER_SKUS",
        description: "Recupera todos los SKUs únicos existentes en la cuenta.",
        formula: "=SHEETSELLER_SKUS(cuenta, sku)",
        ejemplo: `=SHEETSELLER_SKUS("ELECSHOP", "todos")`
    },
    {
        title: "Función SHEETSELLER_STATUS",
        description: "Recupera el status de la publicación (active o paused) basándose en su ID.",
        formula: "=SHEETSELLER_STATUS(cuenta, ids)",
        ejemplo: `=SHEETSELLER_STATUS("ELECSHOP", "MLM3174580262")`
    },
    {
        title: "SHEETSELLER_TITULO",
        description: "Recupera el título del ítem basándose en el ID.",
        formula: "=SHEETSELLER_TITULO(cuenta, id)",
        ejemplo: `=SHEETSELLER_TITULO("ELECSHOP", "MLM3174580262")`
    },
    {
        title: "SHEETSELLER_URL",
        description: "Recupera la URL de la publicación del ítem basándose en su SKU e ID.",
        formula: "=SHEETSELLER_URL(cuenta, skus, ids)",
        ejemplo: `=SHEETSELLER_URL("ELECSHOP", "COM-25-204", "MLM2174397463")`
    },
    {
        title: "SHEETSELLER_STOCK",
        description: "Recupera el stock de la publicación del ítem basándose en su SKU e ID.",
        formula: "=SHEETSELLER_STOCK(cuenta, skus, ids)",
        ejemplo: `=SHEETSELLER_STOCK("ELECSHOP", "COM-25-204", "MLM2174397463")`
    },
    {
        title: "SHEETSELLER_ORDENES",
        description: "Recupera las órdenes de venta individuales dentro de un rango de fechas para una cuenta específica. Esta función cuenta con el parámetro opcional de encabezados que se encarga de mostrar el nombre cada columna en el resultado.",
        formula: "=SHEETSELLER_ORDENES(cuenta, fecha_inicio, fecha_fin, estado, encabezados)",
        ejemplo: `=SHEETSELLER_ORDENES("ELECSHOP", "2022-04-01", "2024-12-30", "Confirmada", "encabezados")`
    },
    {
        title: "SHEETSELLER_TOPVENTASUNIDADES",
        description: "Regresa el top de productos según sus unidades vendidas. Los rangos permitidos para crear tops es máximo 100 productos y mínimo 1 producto. Esta función cuenta con el parámetro opcional de encabezados que se encarga de mostrar el nombre cada columna en el resultado.",
        formula: "SHEETSELLER_TOPVENTASUNIDADES(cuenta, fecha_inicio, fecha_fin, top, encabezados)",
        ejemplo: `=SHEETSELLER_TOPVENTASUNIDADES("ELECSHOP", "2022-04-01", "2024-12-30", 3, "encabezados")`
    },
    {
        title: "SHEETSELLER_TOPVENTASDINERO",
        description: "Regresa el top de productos según sus unidades vendidas. Los rangos permitidos para crear tops es máximo 100 productos y mínimo 1 producto. Esta función cuenta con el parámetro opcional de encabezados que se encarga de mostrar el nombre cada columna en el resultado.",
        formula: "SHEETSELLER_TOPVENTASDINERO(cuenta, fecha_inicio, fecha_fin, top, encabezados)",
        ejemplo: `=SHEETSELLER_TOPVENTASDINERO("ELECSHOP", "2022-04-01", "2024-12-30", 3, "encabezados")`
    },
    {
        title: "SHEETSELLER_VENTASTOTALES",
        description: "Regresa el total en pesos de las ventas totales en el periodo de tiempo indicado.",
        formula: "=SHEETSELLER_VENTASTOTALES(cuenta, fecha_inicial, fecha_final, todos/confirmadas/canceladas).",
        ejemplo: `=SHEETSELLER_VENTASTOTALES("ELECSHOP","2022-04-01", "2024-12-31", "todos")`
    },
    {
        title: "SHEETSELLER_DEVOLUCIONES",
        description: "Nos devuelve las devoluciones que ha tenido la publicación en un rango de tiempo definido. Esta función cuenta con el parámetro opcional llamado encabezados, que se encarga de mostrar los nombres de cada columna.",
        formula: "=SHEETSELLER_DEVOLUCIONES(cuenta,fecha_inicio, fecha_fin, id, encabezados)",
        ejemplo: `=SHEETSELLER_DEVOLUCIONES("ELECSHOP", "2022-04-01", "2024-12-30", "todos", "encabezados")`
    },
    {
        title: "SHEETSELLER_RETIROS",
        description: "Nos muestra los retiros de logística full generados por la cuenta. Los retiros se agrupan por ID de retiro principal y pueden tener uno o más ID de retiro secundario. Esta función cuenta con el parámetro opcional llamado encabezados, que se encarga de mostrar los nombres de cada columna.",
        formula: "=SHEETSELLER_RETIROS(cuenta, fecha_inicial, fecha_final, encabezados)",
        ejemplo: `=SHEETSELLER_RETIROS("ELECSHOP", "2022-07-01", "2024-12-30", "encabezados")`
    },
    {
        title: "SHEETSELLER_CATALOGO",
        description: "Lista las publicaciones que son catálogo, ordenadas de activa, pausada y cerrada. Esta función cuenta con el parámetro opcional llamado encabezados, que se encarga de mostrar los nombres de cada columna.",
        formula: "=SHEETSELLER_CATALOGO(cuenta, tipo_precio, encabezados)",
        ejemplo: `=SHEETSELLER_CATALOGO("ELECSHOP", "base", "encabezados")`
    },
    {
        title: "SHEETSELLER_DASHBOARD",
        description: "Trae información general como precio, stock, id, sku, dias pausados, ventas de 15, 30, 45, 60 y 90 días, comisión a pagar, costo único entre otros más. Incluye todas las publicaciones que tenga la cuenta sin exclusiones. Esta función cuenta con el parámetro opcional llamado encabezados, que se encarga de mostrar los nombres de cada columna.",
        formula: "=SHEETSELLER_DASHBOARD(cuenta, sku, filtro full/nofull, base/promo, encabezados)",
        ejemplo: `=SHEETSELLER_DASHBOARD("ELECSHOP", "todos", "full", "base", "encabezados")`
    },
];


/*const getFunctionDescription = (funcName) => {
    const func = descriptions.find(desc => desc.title === funcName);
    return func || { title: funcName, description: "No description available." };
};*/

export const getAvailableFunctions = () => {
    return descriptions.map(desc => ({ ...desc }));
};